import React from "react";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
    Item,
    Grouping,
    GroupPanel,
    StateStoring,
    ColumnChooser,
} from "devextreme-react/data-grid";
import { Button } from 'devextreme-react/button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "moment/locale/es";
import { Api } from '../../utils/api'
import { useSnackbar } from 'notistack';
import 'devextreme/dist/css/dx.light.css'; // O el tema que desees
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";

const api = new Api('datosMaestro')

function Maestro({
}) {

    const { enqueueSnackbar: Alert } = useSnackbar();
    const navigate = useNavigate();

    const [maestros, setMaestros] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        api.Get('maestro')
            .then((response) => {
                const { data } = response || {}
                setMaestros(data || [])
            })
            .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <div style={{ padding: "0em 2em" }}>
            <DataGrid
                dataSource={maestros}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="nextColumn"
            >
                <Toolbar>
                    <Item location="after">
                        <Button
                            text="Crear"
                            onClick={() => {
                                navigate('maestro/crear')
                            }}
                            icon="add"
                        />
                    </Item>
                    <Item name="groupPanel" />
                    <Item name="columnChooserButton" />
                    <Item name="searchPanel" />
                </Toolbar>
                <Column dataField="idmaestro" caption="id" dataType="number" visible={false} />
                <Column
                    dataField="tablaalias"
                    caption="Alias"
                />
                <Column
                    dataField="tabla"
                    caption="Tabla"
                />
                <Column
                    dataField="idorigen"
                    caption="Origen"
                    calculateGroupValue={(data) => data.idorigen.nombre}
                    calculateCellValue={(data) => data.idorigen.nombre}
                    cellRender={(rowInfo) => {
                        const origen = rowInfo.data.idorigen
                        return (
                            <span>
                                {origen.nombre}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    dataField="sistemadestino"
                    caption="Destino"
                />
                <Column
                    width={'auto'}
                    dataField="idmaestro"
                    caption="Acciones"
                    cellRender={(rowInfo) => {
                        const id = rowInfo.data.idmaestro
                        return (
                            <span>
                                <IconButton aria-label="view" onClick={() => {
                                    navigate(`maestro/${id}/ver`)
                                }}>
                                    <ViewIcon sx={{ color: "black" }} />
                                </IconButton>
                                <IconButton aria-label="edit" onClick={() => {
                                    navigate(`maestro/${id}/editar`)
                                }}>
                                    <EditIcon sx={{ color: "#ffd105" }} />
                                </IconButton>
                                {/* <IconButton aria-label="delete" onClick={() => {
                                }}>
                                    <DeleteIcon sx={{ color: "red" }} />
                                </IconButton> */}
                            </span>
                        )
                    }
                    }
                />
                <Grouping autoExpandAll={true} />
                <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'MaestrosGRID'}
                />
                <ColumnChooser
                    enabled={true}
                    mode="dragAndDrop"
                />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={50} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[50, 100, 150, 200]}
                    showInfo={true}
                />
            </DataGrid>
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Maestro