
import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DropContainer } from '../components/draggable'
import { TreeSelect } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AutoSizer, List } from 'react-virtualized';
import Autocomplete from '@mui/material/Autocomplete';
import { v4 as uuidv4 } from 'uuid';

const get_componente = (campo = {}, value = null, onChange = (value) => { }, drop_prefix = '', origen_campos = []) => {
    const { type } = campo
    const props = {
        required: campo.required,
        label: campo.label,
        id: campo.name,
        //key: campo.name,
        style: {
            width: "100%",
            background: type == "checkbox" ? "transparent" : "white"
        }
    }
    if (type == "string") return <TextField value={value} onChange={(e) => onChange(e.target.value)} {...props} />
    if (type == "number") return <TextField type="number" value={value} onChange={(e) => onChange(e.target.value)} {...props} />
    if (type == "checkbox") return <FormControlLabel control={<Checkbox defaultChecked={campo.default} checked={value} onChange={(e) => onChange(e.target.checked)} />} {...props} />
    if (type == "select") {
        const treeData = campo?.options || []
        return (
            <TreeSelect
                showSearch
                style={{ width: '100%' }}
                value={value}
                treeNodeFilterProp={treeData.some(item => item.title) ? 'title' : 'label'}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={`${campo.label}`}
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeData={treeData}
            />
        )
    }
    if (type == "list" && campo?.fieldsList) {
        return Object.keys(campo.fieldsList).map(key => {
            return (
                <Autocomplete
                    disablePortal
                    id={`${drop_prefix}${campo.name}__${key}`}
                    name={`${drop_prefix}${campo.name}__${key}`}
                    value={value ? value[key] : value}
                    options={origen_campos}
                    onChange={(event, newValue) => {
                        onChange({ ...value || {}, [key]: newValue })
                    }}
                    renderInput={(params) => <TextField {...params} label={campo.fieldsList[key]} />}
                    {...{
                        ...props,
                        ...{
                            style: {
                                ...props.style || {},
                                margin: "15px 0px 15px 0px"
                            }
                        }
                    }}
                />
                // <DropContainer
                //     name={`${drop_prefix}${campo.name}__${key}`}
                //     value={value ? value[key] : value}
                //     id={`${drop_prefix}${campo.name}__${key}`}
                //     Content={() => (
                //         <TextField value={value ? value[key] : value} onChange={(e) => onChange({ ...value || {}, [key]: e.target.value })} disabled {...{ ...props, label: campo.fieldsList[key] }} />
                //     )}
                //     style={{
                //         margin: "15px 0px 15px 0px"
                //     }}
                // />
            )
        })
    }
}

export const AppConfigGenerator = ({ app = {}, setState = (param) => { }, state = {}, origen_campos = [] }) => {
    const ignore = [
        "input-fields",
        "origins",
        "formato-entrada",
        "formato-salida"
    ]

    const [accordions, setAccordions] = React.useState({})
    const [rowsRendered, setRowsRendered] = React.useState(false)
    const [key, setKey] = React.useState(null)
    const accordionsRefs = React.useRef({})
    const listRef = React.useRef(null)
    const [listHeights, setListHeights] = React.useState({})

    const campos_extra = app['input-fields'] || []
    const campos_forEach = campos_extra.filter(campo => campo.forEach && app[campo.forEach]) || []
    const valores_forEach = []


    campos_forEach.forEach(campo => {
        if (!valores_forEach.includes(campo.forEach)) valores_forEach.push(campo.forEach)
        if (!accordionsRefs.current[campo.forEach]) accordionsRefs.current[campo.forEach] = React.createRef(null)
    })

    React.useEffect(() => {
        var temp_body = {
            forEach: {}
        }
        // Se inicializan los valores para cada campo
        valores_forEach.forEach(valor_forEach => {
            app[valor_forEach]?.forEach(valor_origen => {
                campos_forEach.filter(campo => /*campo.onSameObj &&*/ campo.forEach == valor_forEach).forEach(campo => {
                    temp_body = {
                        ...state,
                        ...temp_body,
                        forEach: {
                            ...temp_body.forEach || {},
                            [valor_forEach]: {
                                ...temp_body.forEach[valor_forEach] || {},
                                [valor_origen]: {
                                    ...(temp_body.forEach[valor_forEach] || {})[valor_origen] || {},
                                    [campo.name]: campo.type == 'list' && campo.fieldsList ? {
                                        ...Object.keys(campo.fieldsList).reduce((obj, key) => (obj[key] =
                                            (state.forEach || {})[valor_forEach] ?
                                                state.forEach[valor_forEach][valor_origen] ?
                                                    state.forEach[valor_forEach][valor_origen][campo.name] ?
                                                        state.forEach[valor_forEach][valor_origen][campo.name][key] :
                                                        null : null : null
                                            , obj), {})
                                    } : campo.type == 'list' && !campo.fieldsList ? [] :
                                        (state.forEach || {})[valor_forEach] ?
                                            state.forEach[valor_forEach][valor_origen] ?
                                                state.forEach[valor_forEach][valor_origen][campo.name] :
                                                null : null,
                                }
                            }
                        }
                    }
                })
            })
        })
        campos_extra.filter(campo => !campo.forEach || !app[campo.forEach]).map(campo => {
            temp_body = {
                ...temp_body,
                [campo.name]: campo.type == 'list' && campo.fieldsList ? {
                    ...Object.keys(campo.fieldsList).reduce((obj, key) => (obj[key] =
                        state ?
                            state[campo.name] ?
                                state[campo.name][key] :
                                null : null
                        , obj), {})
                } : campo.type == 'list' && !campo.fieldsList ? [] :
                    state ? state[campo.name] : null
            }
        })
        setState({ temp_body })
    }, [])

    React.useEffect(() => {
        Object.keys(accordionsRefs.current).map(key => {
            accordionsRefs.current[key].current?.recomputeRowHeights()
            if (key == null) {
                accordionsRefs.current[key].current?.forceUpdateGrid()
                setKey(uuidv4())
            }
        })
    }, [accordions])

    // Se controlan las alturas de los acordeones
    React.useEffect(() => {
        const temp_accordions = {}
        const temp_heights = {}
        valores_forEach.map(valor_forEach => {
            temp_heights[valor_forEach] = listHeights[valor_forEach]
            app[valor_forEach]?.map((valor_origen, index) => {
                const suffix = `__${valor_forEach}__${valor_origen}`
                const id = `Accordion${suffix}`
                const summaryId = `AccordionSummary${suffix}`
                const detailId = `AccordionDetail${suffix}`
                const data = accordions[id] || {}
                data.summaryHeight = document.getElementById(summaryId)?.offsetHeight || 0
                data.detailHeight = document.getElementById(detailId)?.offsetHeight || 0
                data.height = data.summaryHeight + data.detailHeight
                data.expanded = data.expanded || false
                data.visible = data.visible || false
                temp_accordions[id] = data
                temp_heights[valor_forEach] = !temp_heights[valor_forEach] || (temp_heights[valor_forEach] || 0) < data.height ? data.height : temp_heights[valor_forEach]
            })
        })
        setListHeights({
            ...listHeights,
            ...temp_heights
        })
        setAccordions({
            ...accordions,
            ...temp_accordions
        })
    }, [rowsRendered])

    function getRef(name) {
        const ref = React.createRef(null)
        accordionsRefs[name] = accordionsRefs[name] || ref
        return ref
    }

    return (
        <div style={{ overflow: 'auto' }}>
            {/* Campos Generales */}
            {campos_extra.filter(campo => !campo.forEach || !app[campo.forEach]).length > 0 &&
                <h3 style={{ padding: "0px 0px 0px 1em", margin: "1em 0em 0em 0em" }} key={'campos_generales_label'}>Campos Generales</h3>
            }
            {campos_extra.filter(campo => !campo.forEach || !app[campo.forEach]).map(campo => {
                // Retorna los campos que no tienen forEach
                return <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={campo.name}>
                    <div style={{ padding: "1em 2em 1em 2em", width: "100%" }}>
                        {get_componente(campo, state[campo.name], (value) => setState({ temp_body: { ...state, [campo.name]: value } }), '', origen_campos)}
                    </div>
                </div>
            })}
            {valores_forEach.map((valor_forEach, index) => {
                const ref = getRef(valor_forEach)
                const rowCount = app[valor_forEach]?.length || 0
                return (
                    <>
                        <h3 style={{ padding: "0px 0px 0px 1em", margin: "0em 0em 0em 0em" }} key={`${valor_forEach}_label`}>{valor_forEach}</h3>
                        <AutoSizer disableHeight>
                            {({ width }) => {
                                return (
                                    <List
                                        key={key + index}
                                        ref={accordionsRefs.current[valor_forEach]}
                                        width={width}
                                        rowCount={rowCount}
                                        height={listHeights[valor_forEach] || 300}
                                        rowHeight={({ index }) => {
                                            const valor_origen = app[valor_forEach] ? app[valor_forEach][index] : null
                                            const suffix = `__${valor_forEach}__${valor_origen}`
                                            const id = `Accordion${suffix}`
                                            const height = accordions[id]?.expanded ? accordions[id]?.height || 64 : accordions[id]?.summaryHeight || 64
                                            return height
                                        }}
                                        onRowsRendered={() => setRowsRendered(!rowsRendered)}
                                        rowRenderer={({
                                            key, index, isScrolling,
                                            isVisible, style
                                        }) => {
                                            // Se obtiene el valor de origen
                                            const valor_origen = app[valor_forEach] ? app[valor_forEach][index] : null
                                            // Se crean las referencias para acceder a las propiedades de los acordeones
                                            const suffix = `__${valor_forEach}__${valor_origen}`
                                            //Se crean los ids
                                            const id = `Accordion${suffix}`
                                            const summaryId = `AccordionSummary${suffix}`
                                            const detailId = `AccordionDetail${suffix}`
                                            return (
                                                isVisible && valor_origen ?
                                                    <div style={{ ...style }}>
                                                        <Accordion
                                                            expanded={accordions[id]?.expanded || false}
                                                            style={{ background: 'transparent' }}
                                                            id={id}
                                                            onChange={(e, expanded) => {
                                                                setAccordions({
                                                                    ...accordions,
                                                                    [id]: {
                                                                        ...accordions[id],
                                                                        expanded
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1-content"
                                                                id={summaryId}
                                                            >
                                                                <h4 style={{ padding: "0px 0px 0px 2em", margin: "0.5em 0em 0.5em 0em" }} key={valor_origen}>{valor_origen}</h4>
                                                            </AccordionSummary>
                                                            <AccordionDetails id={detailId}>
                                                                {campos_forEach.filter(campo => /*campo.onSameObj &&*/ campo.forEach == valor_forEach).map(campo => {
                                                                    return (
                                                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={campo.name}>
                                                                            <div style={{ padding: "1em 2em 1em 2em", width: "100%" }}>
                                                                                {get_componente(
                                                                                    campo,
                                                                                    (state.forEach || {})[valor_forEach] ?
                                                                                        state.forEach[valor_forEach][valor_origen] ?
                                                                                            state.forEach[valor_forEach][valor_origen][campo.name] :
                                                                                            null : null,
                                                                                    (value) => {
                                                                                        setState({
                                                                                            temp_body: {
                                                                                                ...state,
                                                                                                forEach: {
                                                                                                    ...state.forEach || {},
                                                                                                    [valor_forEach]: {
                                                                                                        ...(state.forEach || {})[valor_forEach] || {},
                                                                                                        [valor_origen]: {
                                                                                                            ...(state.forEach || {})[valor_forEach][valor_origen] || {},
                                                                                                            [campo.name]: value
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    },
                                                                                    `forEach__${valor_forEach}__${valor_origen}__`,
                                                                                    origen_campos
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                    : null
                                            )
                                        }}
                                    />
                                )
                            }}
                        </AutoSizer>
                    </>
                )
            })}
        </div>
    )
}