import React, { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';
import api from '../../../../utils/api';
import { Switch } from '@mui/material';

const handleStyle = { left: 10 };

const conectorWidth = "20em"

// TIPOS DE CONECTOR
const TIPO = {
    ORIGEN: 1,
    ACCION: 2,
    DESTINO: 3
}

const TIPO_ACCION = {
    CONCAT: 1,
    RENAME: 2,
    SCRIPT: 3,
    ROUTER: 4,
    JOIN: 5,
}

const deleteConector = (id, setLoading, setFullFlujo, Alert) => {
    confirmAlert({
        title: 'Confirmación',
        message: '¿Está seguro de eliminar el conector?',
        overlayClassName: "confirm-alert",
        buttons: [
            {
                label: 'Sí',
                onClick: () => {
                    setLoading(true)
                    api.Delete(`conector/${id.replace('conector:', '')}`)
                        .then(response => {
                            const content = response.data
                            setFullFlujo(
                                content || {},
                                content?.origen || {},
                                content?.destino || {},
                                content?.edges || [],
                                content?.conectores || []
                            )
                            Alert(`Conector eliminado correctamente.`, { variant: 'success' })
                        })
                        .catch(e => {
                            const { name, message, code } = e || {}
                            Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                }
            },
            {
                label: 'No',
                onClick: () => { }
            }
        ]
    });
}

export default function Conector({
    id,
    data,
    type,
    xPos,
    yPos,
    setSourceConf,
    setLoading,
    setFullFlujo,
    isFocused=false,
}) {

    const { nombre, descripcion, accion } = data

    const { enqueueSnackbar: Alert } = useSnackbar();

    return (
        <>
            {(accion == TIPO.DESTINO) && <Handle type="target" position={Position.Top} />}
            <div id={id} style={{ padding: "0.5em 0em 0.5em 0em" }}>
                <Card variant="outlined" style={{ width: conectorWidth, boxShadow: isFocused ? '0 0 30px 10px #220022' : 'none' }}>
                    <CardHeader
                        avatar={
                            <IconButton aria-label="delete" onClick={() => {
                                deleteConector(id, setLoading, setFullFlujo, Alert)
                            }}>
                                <DeleteIcon sx={{ color: "#DACF82" }} />
                            </IconButton>
                        }
                        action={
                            <IconButton aria-label="settings" onClick={() => {
                                setSourceConf({
                                    id,
                                    data,
                                    type,
                                    position: {
                                        x: xPos,
                                        y: yPos
                                    }
                                })
                            }}>
                                <SettingsIcon sx={{ color: "#FFFFFF" }} />
                            </IconButton>
                        }
                        title={nombre}
                        style={{ background: "#220022", color: "white" }}
                    />
                    <CardContent>
                        <Typography variant="body2">
                            {descripcion}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            {(accion == TIPO.ORIGEN) && <Handle type="source" position={Position.Bottom} />}
        </>
    );
}

function ConectorAccion({
    id,
    data,
    type,
    xPos,
    yPos,
    setSourceConf,
    setLoading,
    setFullFlujo,
    isFocused=false,
}) {

    const { nombre, descripcion, accion, tipo_accion } = data

    const { enqueueSnackbar: Alert } = useSnackbar();

    return (
        <>
            {(accion == TIPO.ACCION) &&
                <>
                    <Handle type="target" position={Position.Top} />
                    <div id={id} style={{ padding: "0.5em 0em 0.5em 0em" }}>
                        <Card variant="outlined" style={{ width: conectorWidth, boxShadow: isFocused ? '0 0 30px 10px #780e78' : 'none' }}>
                            <CardHeader
                                avatar={
                                    <IconButton aria-label="delete" onClick={() => {
                                        deleteConector(id, setLoading, setFullFlujo, Alert)
                                    }}>
                                        <DeleteIcon sx={{ color: "#DACF82" }} />
                                    </IconButton>
                                }
                                action={
                                    <IconButton aria-label="settings" onClick={() => {
                                        setSourceConf({
                                            id,
                                            data,
                                            type,
                                            position: {
                                                x: xPos,
                                                y: yPos
                                            }
                                        })
                                    }}>
                                        <SettingsIcon sx={{ color: "#FFFFFF" }} />
                                    </IconButton>
                                }
                                title={nombre}
                                style={{ background: "#780e78", color: "white" }}
                            />
                            <CardContent>
                                <Typography variant="body2">
                                    {descripcion}
                                </Typography>
                            </CardContent>
                            <CardActions
                                style={{ background: "#780e78", color: "white" }}
                            >

                            </CardActions>
                        </Card>
                    </div>
                    <Handle type="source" position={Position.Bottom} />
                </>
            }
        </>
    );
}

export { ConectorAccion, TIPO_ACCION, conectorWidth }