import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

const TagsInput = ({ 
    selectedTags, 
    tags, 
    setTags,
    label='Etiquetas',
    placeholder='',
    endAdornment,
}) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            multiple
            freeSolo
            filterSelectedOptions
            options={tags || []}
            value={selectedTags || []}
            onChange={(event, newValue) => {
                setTags(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default TagsInput;