import React from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import api from "../../utils/api";
import { useSnackbar } from 'notistack';
import { AuthContext } from "../../utils/context/authContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Login() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false)
  const { login, setSelectedUser } = React.useContext(AuthContext);

  const { enqueueSnackbar: Alert } = useSnackbar();
  const navigate = useNavigate();

  function updateUsername(e) {
    setUsername(e.target.value);
  }

  function updatePassword(e) {
    setPassword(e.target.value);
  }

  function credentialsComplete() {
    return username !== "" && password !== "";
  }

  const handleOnSubmitLogin = (e) => {
    e?.preventDefault();
    setSelectedUser(null);
    if (credentialsComplete()) {
      setLoading(true)
      api.Post('usuario/login/', { usuario: username, contra: password })
        .then((response) => {
          const { data, status } = response || {}
          const { detail, content } = data || {}
          login(JSON.stringify(content))
          navigate(`/`);
        })
        .catch((e) => {
          const { response } = e || {}
          const { data, status } = response || {}
          const { detail, content } = data || {}
          Alert(`${detail}: ${content || 'Error inesperado.'}`, { variant: 'error' })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  };

  return (
    <div style={centeredDiv}>
      <div style={logoContainer}>
        <img
          src="/logo_zafra_55-02.png"
          alt="logo"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
      <div style={loginContainer}>
        <div style={loginCard}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            top: '-70px' // Ajusta este valor según el tamaño de tu imagen
          }}>
            <img
              src="/ILU.svg"
              alt="Imagen de perfil"
              style={{
                width: '100px', // Ajusta este valor según el tamaño que desees
                height: '100px', // Ajusta este valor según el tamaño que desees
                borderRadius: '50%',
                border: '2px solid white',
                objectFit: 'cover',
                background: 'white'
              }}
            />
          </div>
          <form onSubmit={handleOnSubmitLogin}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', top: '-50px', position: 'relative' }}>
              <TextField
                id="outlined-username"
                label="Usuario"
                variant="outlined"
                value={username}
                onChange={updateUsername}
                sx={{ margin: "18px 0", background: 'white' }}
              />
              <TextField
                id="outlined-password"
                label="Contraseña"
                variant="outlined"
                value={password}
                onChange={updatePassword}
                type="password"
                sx={{ margin: "18px 0", background: 'white' }}
              />
              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ margin: "5px 0", background: 'rgba(92, 93, 131, 1)', color: 'white' }}
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Pantalla de carga */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

const centeredDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  height: "calc(100vh - 0px)",
  background: "linear-gradient(103.86deg, rgba(94, 185, 114, 0.88) -9.3%, #2E8276 19.46%, #328A76 50.35%, #41A96F 68.22%, #1D4758 94.93%)"
};

const loginContainer = {
  display: "flex",
  flexDirection: "column",
  width: "50%",
  minWidth: "400px",
  height: "350px",
  alignSelf: "center",
  justifyContent: "center",
}

const loginCard = {
  background: "rgba(59, 59, 59, 0.73)",
  padding: "20px",
  border: "3px solid rgb(128, 123, 123)",
  display: "flex",
  flexDirection: "column",
  width: "50%",
  minWidth: "400px",
  height: "350px",
  alignSelf: "center",
};

const logoContainer = {
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  width: "50%",
  minWidth: "400px",
  height: "550px",
  alignSelf: "center",
}