import React, { useEffect, useRef } from "react";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
    Item,
    Grouping,
    GroupPanel,
    StateStoring,
    ColumnChooser,
} from "devextreme-react/data-grid";
import { Button as GridButton } from 'devextreme-react/button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import moment from "moment";
// import "moment/locale/es";
import api from '../../utils/api'
import { useSnackbar } from 'notistack';
import 'devextreme/dist/css/dx.light.css'; // O el tema que desees

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';

function HojaS3({
}) {

    const { enqueueSnackbar: Alert } = useSnackbar();

    const [sheets, setSheets] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [nombre, setNombre] = React.useState('')
    const [descripcion, setDescripcion] = React.useState('')
    const [file, setFile] = React.useState(null)

    const onCreationClose = () => {
        setFile(null)
        setNombre('')
        setDescripcion('')
        setOpen(false)
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    React.useEffect(() => {
        setLoading(true)
        api.Get('hojaS3')
            .then((response) => {
                const { data } = response || {}
                setSheets(data || [])
            })
            .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleFile = (event) => {
        if (event?.target?.files?.length > 0) {
            setFile(event.target.files[0])
        } else {
            setFile(null)
        }
    }

    const deleteFile = (id) => {
        confirmAlert({
            title: 'Confirmación',
            message: '¿Está seguro de eliminar el achivo?',
            overlayClassName: "confirm-alert",
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => {
                        setLoading(true)
                        api.Delete(`hojaS3/${id}`)
                            .then((response) => {
                                const { data } = response || {}
                                const { content } = data || {}
                                setSheets(content)
                                Alert('Archivo eliminado exitosamente.', { variant: 'success' })
                            })
                            .catch(e => {
                                const { name, message, code } = e || {}
                                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const submitFile = () => {
        if (!nombre || !file) {
            Alert('Por favor, complete los campos requeridos (nombre y archivo).', { variant: 'warning' })
            return
        }
        setLoading(true)
        api.Post('hojaS3/', { nombre, descripcion, file }, true)
            .then((response) => {
                const { data } = response || {}
                const { content } = data || {}
                setSheets(content)
                Alert('Archivo creado exitosamente.', { variant: 'success' })
                onCreationClose()
            })
            .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <div style={{ margin: "0 40px 40px 40px" }}>
            <DataGrid
                dataSource={sheets}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="nextColumn"
            >
                <Toolbar>
                    <Item location="after">
                        <GridButton
                            text="Agregar"
                            onClick={() => {
                                setOpen(true)
                            }}
                            icon="add"
                        />
                    </Item>
                    {/* <Item name="groupPanel" /> */}
                    {/* <Item name="columnChooserButton" /> */}
                    <Item name="searchPanel" />
                </Toolbar>
                <Column dataField="id" caption="id" dataType="number" visible={false} />
                <Column
                    dataField="nombre"
                    caption="Nombre"
                />
                <Column
                    dataField="descripcion"
                    caption="Descripción"
                />
                <Column
                    dataField="s3_uri"
                    caption="S3 URI"
                    calculateGroupValue={(data) => `${data.s3_uri}`}
                    calculateCellValue={(data) => `${data.s3_uri}`}
                    cellRender={(rowInfo) => {
                        const s3_uri = rowInfo.data.s3_uri || ''
                        return (
                            <span>
                                <IconButton
                                    aria-label="copy"
                                    onClick={() => {
                                        navigator.clipboard.writeText(s3_uri);
                                        Alert(`URI copiada.`, { variant: 'success', autoHideDuration: 1500 })
                                    }}
                                >
                                    <CopyIcon />
                                </IconButton>
                                {s3_uri}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    width={'auto'}
                    allowFiltering={false}
                    allowSorting={false}
                    allowSearch={false}
                    allowGrouping={false}
                    allowReordering={false}
                    allowResizing={false}
                    cellRender={(rowInfo) => {
                        const id = rowInfo.data.id
                        return (
                            <span>
                                <IconButton aria-label="delete" onClick={() => {
                                    deleteFile(id)
                                }}>
                                    <DeleteIcon sx={{ color: "red" }} />
                                </IconButton>
                            </span>
                        )
                    }
                    }
                />
                <Grouping autoExpandAll={true} />
                <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'hojasS3GRID'}
                />
                <ColumnChooser
                    enabled={true}
                    mode="dragAndDrop"
                />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={50} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[50, 100, 150, 200]}
                    showInfo={true}
                />
            </DataGrid>
            {/* S3 FILE CREATION DIALOG */}
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={onCreationClose}
            >
                <DialogTitle>
                    Subir nueva hoja S3
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCreationClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "center" }}>
                        <div style={{ display: 'flex', padding: "2em 1em 1em 1em", width: "100%" }}>
                            <div style={{ display: 'flex', padding: "0em 0.5em 0em 0em", width: "50%" }}>
                                <TextField
                                    required
                                    id="nombreS3sheet"
                                    label="Nombre"
                                    variant="outlined"
                                    value={nombre}
                                    onChange={(event) => setNombre(event.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div style={{ display: 'flex', padding: "0em 0em 0em 0.5em", width: "50%" }}>
                                <TextField
                                    id="descripcionS3sheet"
                                    label="Descripción"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={descripcion}
                                    onChange={(event) => setDescripcion(event.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: "1em 0em 0.5em 0em", width: "100%", justifyContent: 'center' }}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<InsertDriveFileIcon />}
                            >
                                Seleccionar archivo
                                <VisuallyHiddenInput
                                    type="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onInput={handleFile}
                                />
                            </Button>
                        </div>
                        <div style={{ display: 'flex', padding: "0.5em 0em 0.5em 0em", width: "100%", justifyContent: 'center' }}>
                            <span>
                                {file?.name}
                                {file &&
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => setFile(null)}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                }
                            </span>
                        </div>
                        <div style={{ display: 'flex', padding: "0.5em 1em 1em 0em", width: "100%", justifyContent: 'flex-end' }}>
                            <Button
                                onClick={onCreationClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                disabled={!nombre || !file}
                                onClick={submitFile}
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000000000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default HojaS3