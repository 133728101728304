import axios from "axios";

const LOCAL = 'LOCAL'
const DEV = 'DEV'
const PROD = 'PROD'

const environ = process.env.REACT_APP_BUILD_ENV || LOCAL

const HOST = environ == LOCAL ? 'http://localhost:8000/' : // LOCAL
            environ == DEV ? 'https://desamcubackend.launion.com.gt/' : // DESARROLLO
            environ == PROD ? 'https://mcubackend.launion.com.gt/' : /* PRODUCCION */ ''

class Api {

    constructor(application='api') {
        this.application = application;
    }

    async Get(uri, params = null, responseType = 'json') {
        const urlParameters =
            params != null
                ? "?" +
                Object.keys(params)
                    .map(
                        (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
                    )
                    .join("&")
                : "";
        const endpoint = `${HOST}${this.application}/${uri}${uri.endsWith('/') ? '' : '/'}${params === null ? "" : urlParameters}`;
        const storage = localStorage.getItem("user");
        const token = JSON.parse(storage || "{}").token;
        const request = await axios.get(endpoint, { responseType, headers: { ...(token ? { Authorization:  `Token ${token}` } : {}) } })
        return request;
    }

    async Post(uri, formData, hasFiles=false, params = {}) {
        const headers = {
            'Content-Type': 'application/json'
        }
        if (hasFiles) {
            headers['Content-Type'] = 'multipart/form-data'
            const data = new FormData();
            for (const key in formData) {
                data.append(key, formData[key]);
            }
            formData = data;
        }
        const storage = localStorage.getItem("user");
        const token = JSON.parse(storage || "{}").token;
        const request = await axios.post(`${HOST}${this.application}/${uri}${uri.endsWith('/') ? '' : '/'}`, formData, { params, headers: { ...headers, ...(token ? { Authorization:  `Token ${token}` } : {}) } });
        return request;
    }

    async Put(uri, formData) {
        const storage = localStorage.getItem("user");
        const token = JSON.parse(storage || "{}").token;
        const request = await axios.put(`${HOST}${this.application}/${uri}${uri.endsWith('/') ? '' : '/'}`, formData, { headers: { ...(token ? { Authorization:  `Token ${token}` } : {}) } });
        return request;
    }

    async Delete(uri) {
        const storage = localStorage.getItem("user");
        const token = JSON.parse(storage || "{}").token;
        const request = await axios.delete(`${HOST}${this.application}/${uri}${uri.endsWith('/') ? '' : '/'}`, { headers: { ...(token ? { Authorization:  `Token ${token}` } : {}) } });
        return request;
    }
}

export { Api }
export default new Api();
