import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../utils/api'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { confirmAlert } from 'react-confirm-alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Chip from '@mui/material/Chip';
import TagsInput from '../../components/TagsInput';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Grid as SwiperGrid } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';

const Flujo = () => {

  const { enqueueSnackbar: Alert } = useSnackbar();
  const navigate = useNavigate();

  const [nuevoFlujo, setNuevoFlujo] = React.useState(false)
  const [isDuplicate, setIsDuplicate] = React.useState(false)
  const [flujoId, setFlujoId] = React.useState(null)
  const [nombre, setNombre] = React.useState(null)
  const [newTags, setNewTags] = React.useState([])
  const [descripcion, setDescripcion] = React.useState(null)

  const [flujos, setFlujos] = React.useState({})
  const [tags, setTags] = React.useState([])
  const [searchTags, setSearchTags] = React.useState([])
  const [openedFlujoDials, setOpenedFlujoDials] = React.useState({})

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    api.Get(`flujo`)
      .then(response => {
        const { data } = response
        const { content } = data || {}
        const { flujos, tags } = content || {}
        setFlujos(flujos || {})
        setTags(tags || [])
      })
      .catch(e => {
        const { name, message, code } = e || {}
        Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const getFlujosByFilter = (tags) => {
    setLoading(true)
    api.Get(`flujo`, { tags })
      .then(response => {
        const { data } = response
        const { content } = data || {}
        const { flujos } = content || {}
        setFlujos(flujos || {})
      })
      .catch(e => {
        const { name, message, code } = e || {}
        Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const duplicarFlujo = (id, tag) => {
    const flujo = flujos[tag].find(flujo => flujo.id === id)
    const duplicados = flujos[tag].filter(flujo1 => flujo1.nombre.includes(flujo.nombre))
    const length = duplicados.length
    setIsDuplicate(true)
    setFlujoId(id)
    setNombre(`${flujo.nombre} (Copia${length && length > 1 ? ' ' + (length - 1) : ''})`)
    setDescripcion(flujo.descripcion)
    setNewTags(flujo.tags || [])
    setNuevoFlujo(true)
  }

  const saveDuplicarFlujo = () => {
    setLoading(true)
    api.Post('flujo/duplicado/', {
      id: flujoId,
      nombre,
      descripcion,
      tags: newTags
    })
      .then(response => {
        const { data, status } = response || {}
        const { detail, content } = data || {}
        const { id } = content || {}
        if (id) {
          navigate(`flujo/${id}`)
        } else {
          Alert(`Hubo un error al cargar el nuevo flujo`, { variant: 'error' })
        }
      })
      .catch(e => {
        const { response } = e || {}
        const { data, status } = response || {}
        const { detail, content } = data || {}
        Alert(`${detail}: ${content}`, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteFlujo = (id) => {
    confirmAlert({
      title: 'Confirmación',
      message: '¿Está seguro de eliminar el flujo?',
      overlayClassName: "confirm-alert",
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            setLoading(true)
            api.Delete(`flujo/${id}`)
              .then(response => {
                const { data } = response
                Alert(`Flujo eliminado correctamente.`, { variant: 'success' })
                setFlujos(data)
              })
              .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
              })
              .finally(() => {
                setLoading(false)
              })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  const closeNuevoDialog = () => {
    setNuevoFlujo(false)
    setNombre(null)
    setDescripcion(null)
    setIsDuplicate(false)
    setFlujoId(null)
  }

  const guardarNuevoFlujo = () => {
    setLoading(true)
    api.Post('flujo/', {
      nombre,
      descripcion,
      tags: newTags
    })
      .then(response => {
        const { data, status } = response || {}
        const { detail, content } = data || {}
        const { id } = content || {}
        if (id) {
          navigate(`flujo/${id}`)
        } else {
          Alert(`Hubo un error al cargar el nuevo flujo`, { variant: 'error' })
        }
      })
      .catch(e => {
        const { response } = e || {}
        const { data, status } = response || {}
        const { detail, content } = data || {}
        Alert(`${detail}: ${content}`, { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box sx={{ flexGrow: 1, padding: "1rem 3rem" }}>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
          sx={{ fontSize: '12px' }}
        >
          {/* <Grid sx={{ order: { xs: 2, sm: 1 } }}>
            <Typography variant="h3" gutterBottom>
              Flujos Activos
            </Typography>
          </Grid> */}
          <Grid container columnSpacing={1} xs={12} sx={{ order: { xs: 1, sm: 2 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid xs={8}>
              <TagsInput
                tags={tags}
                setTags={setSearchTags}
                selectedTags={searchTags}
                label='Buscar'
                endAdornment={
                  <IconButton>
                    <SearchIcon onClick={() => getFlujosByFilter(searchTags)} />
                  </IconButton>
                }
              />
            </Grid>
            <Grid>
              <Button variant="contained" startIcon={<AddIcon />} color='primary' onClick={() => setNuevoFlujo(true)}>
                Crear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {Object.keys(flujos).map(tag => {
          const tagFlujos = flujos[tag] || []
          return (
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  {tag.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Swiper
                  breakpoints={{
                    320: { slidesPerView: 1, },
                    640: { slidesPerView: 2, },
                    768: { slidesPerView: 3, },
                    1920: { slidesPerView: 4, },
                  }}
                  spaceBetween={30}
                  grid={{
                    rows: 2,
                    fill: 'row'
                  }}
                  navigation={true}
                  modules={[SwiperGrid, Navigation]}
                  style={{ width: '100%', padding: 5, }}
                >
                  {tagFlujos.map(flujo => {
                    return (
                      <SwiperSlide style={{ alignSelf: 'center' }} key={`${tag}-${flujo.id}`}>
                        <Grid item xs={12} sx={{ padding: '0px 10%', position: 'relative' }}>
                          <Backdrop open={openedFlujoDials[`${tag}-${flujo.id}`]} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: '0px 10%', borderRadius: '15px' }} />
                          <Card sx={{ width: "100%", background: "#edf1f4", borderRadius: '15px' }}>
                            <CardHeader
                              title={
                                <Typography variant="h5" style={{ display: 'flex', justifyContent: 'center', color: '#0A3D5B', fontWeight: 'bold' }}>
                                  {flujo.nombre}
                                </Typography>
                              }
                            />
                            <CardContent>
                              <Typography variant="body1" style={{ color: '#4A6D7C' }}>
                                {flujo.descripcion}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '50px' }}>
                              <div style={{ width: 'calc(100% - 45px)' }}>
                                {flujo.tags.map(t => {
                                  return (
                                    <Chip label={t} style={{ background: '#1a3a4b', color: 'white', margin: '5px' }} />
                                  )
                                })}
                              </div>
                              <SpeedDial
                                ariaLabel="flujo-actions"
                                icon={<SpeedDialIcon />}
                                direction='left'
                                FabProps={{
                                  size: 'small',
                                  style: { background: '#FF6F61', color: '#FFFFFF' }
                                }}
                                style={{ position: 'absolute', right: '12%' }}
                                onClose={() => setOpenedFlujoDials({})}
                                onOpen={() => setOpenedFlujoDials({ [`${tag}-${flujo.id}`]: true })}
                              >
                                <SpeedDialAction
                                  key={'ver'}
                                  icon={<VisibilityIcon color='black' />}
                                  tooltipTitle={'Ver'}
                                  onClick={() => navigate(`flujo/${flujo.id}`)}
                                />
                                <SpeedDialAction
                                  key={'duplicar'}
                                  icon={<ContentCopyIcon color='black' />}
                                  tooltipTitle={'Duplicar'}
                                  onClick={() => duplicarFlujo(flujo.id, tag)}
                                />
                                <SpeedDialAction
                                  key={'eliminar'}
                                  icon={<DeleteIcon color='error' />}
                                  tooltipTitle={'Eliminar'}
                                  onClick={() => deleteFlujo(flujo.id)}
                                />
                              </SpeedDial>
                            </CardActions>
                          </Card>
                        </Grid>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </Grid>
            </Grid>
          )
        })}
      </Grid>

      {/* Dialogo de creación para nuevo flujo */}
      <Dialog onClose={closeNuevoDialog} open={nuevoFlujo} maxWidth='md' fullWidth>
        <DialogTitle>{isDuplicate ? 'Duplicar' : 'Nuevo'} flujo</DialogTitle>
        <form style={{ padding: "0px 25px 25px 25px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="nombre-flujo"
                label="Nombre"
                variant="outlined"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                required
                id="descripcion-flujo"
                label="Descripción"
                variant="outlined"
                multiline
                rows={4}
                value={descripcion}
                onChange={(event) => setDescripcion(event.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TagsInput
                tags={tags}
                setTags={setNewTags}
                selectedTags={newTags}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
              <Button variant="text" color='primary' onClick={closeNuevoDialog}>Cancelar</Button>
              <Button variant="contained" color='primary' onClick={isDuplicate ? saveDuplicarFlujo : guardarNuevoFlujo}>Crear</Button>
            </Grid>
          </Grid>
        </form>
      </Dialog>

      {/* Pantalla de carga */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Flujo