import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import EditableText from '../../../../components/editableText';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { routes_conditions, join_operators } from '../../../../utils/constants';

const Join = ({
    id,
    index,
    value,
    campo,
    conf,
    onSelectedConfChange,
    configuracion_salida,
    campos_origen,
    sources,
    app = {},
}) => {
    console.log('0join data', campo)
    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: 'row', justifyContent: "flex-start", padding: "0em 2em 0em 2em" }}>
                <CancelIcon
                    style={{ alignSelf: 'center', cursor: 'pointer' }}
                    onClick={() => {
                        const newCampos = configuracion_salida?.campos || []
                        newCampos.splice(index, 1)
                        onSelectedConfChange({
                            configuracion_salida: {
                                ...configuracion_salida || {},
                                campos: newCampos
                            }
                        })
                    }}
                />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0em 0.5em 0em 0.5em' }}>
                    <>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: "1em 0em 1em 0em" }}>
                            {/* DATA 1 */}
                            <Autocomplete
                                id={`campo-condition-${index}-data1`}
                                options={sources.filter((source) => source?.id !== conf?.data0) || []}
                                value={sources.filter((source) => source?.id !== conf?.data0).find((source) => source?.id === campo?.data1) || null}
                                getOptionLabel={(option) => option?.nombre + ' - ' + option?.descripcion || ''}
                                getOptionKey={(option) => option?.id || ''}
                                onChange={(event, newValue) => {
                                    const campos = configuracion_salida?.campos || []
                                    campos[index].data1 = newValue?.id
                                    onSelectedConfChange({
                                        configuracion_salida: {
                                            ...configuracion_salida || {},
                                            campos
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField {...params} label="Fuente secundaria" />}
                                style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                            />
                        </div>
                        {/* CONDICIONES */}
                        {campo.conditions?.map((condition, indexCondition) => {
                            return (
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1em 0em 0em 0em' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {/* CANCEL ICON */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                            <CancelIcon
                                                style={{ alignSelf: 'center', cursor: 'pointer' }}
                                                onClick={() => {
                                                    const campos = configuracion_salida?.campos || []
                                                    const conditions = campos[index].conditions || []
                                                    conditions.splice(indexCondition, 1)
                                                    campos[index].conditions = conditions
                                                    onSelectedConfChange({
                                                        configuracion_salida: {
                                                            ...configuracion_salida || {},
                                                            campos
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: "0.5em" }}>
                                                {/* FIELD 0 */}
                                                <div style={{ display: 'flex', width: "33%" }}>
                                                    <Autocomplete
                                                        id={`campo-condition-${index}-field0`}
                                                        options={app[`conector:${conf?.data0}`]?.campos || []}
                                                        value={(app[`conector:${conf?.data0}`]?.campos || []).find((field) => field === condition?.field0) || null}
                                                        getOptionLabel={(option) => option || ''}
                                                        getOptionKey={(option) => option || ''}
                                                        onChange={(event, newValue) => {
                                                            const campos = configuracion_salida?.campos || []
                                                            const conditions = campos[index].conditions || []
                                                            conditions[indexCondition].field0 = newValue
                                                            campos[index].conditions = conditions
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...configuracion_salida || {},
                                                                    campos
                                                                }
                                                            })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Campo 1" />}
                                                        style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                    />
                                                </div>
                                                {/* CONDITIONAL */}
                                                <div style={{ display: 'flex', width: "33%" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={`campo-${index}-condition-${indexCondition}-condicional-label`}>
                                                            Condicional
                                                        </InputLabel>
                                                        <Select
                                                            disabled
                                                            labelId={`campo-${index}-condition-${indexCondition}-condicional-label`}
                                                            id={`campo-${index}-condition-${indexCondition}-condicional`}
                                                            label="Condicional"
                                                            style={{ width: "100%", background: "white" }}
                                                            value={campo?.conditions[indexCondition]?.condition || ''}
                                                            onChange={(event) => {
                                                                const campos = configuracion_salida?.campos || []
                                                                const conditions = campos[index].conditions || []
                                                                conditions[indexCondition].condition = event.target.value
                                                                campos[index].conditions = conditions
                                                                onSelectedConfChange({
                                                                    configuracion_salida: {
                                                                        ...configuracion_salida || {},
                                                                        campos
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {routes_conditions?.map((condition) => (
                                                                <MenuItem
                                                                    key={`${condition.nombre} - ${condition.value}`}
                                                                    value={condition.value}
                                                                >
                                                                    <Tooltip title={condition.hint} placement='left'>
                                                                        {`${condition.nombre}`}
                                                                    </Tooltip>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* FIELD 1 */}
                                                <div style={{ display: 'flex', width: "33%" }}>
                                                    <Autocomplete
                                                        id={`campo-condition-${index}-field1`}
                                                        options={app[`conector:${campo?.data1}`]?.campos || []}
                                                        value={(app[`conector:${campo?.data1}`]?.campos || []).find((field) => field === condition?.field1) || null}
                                                        getOptionLabel={(option) => option || ''}
                                                        getOptionKey={(option) => option || ''}
                                                        onChange={(event, newValue) => {
                                                            const campos = configuracion_salida?.campos || []
                                                            const conditions = campos[index].conditions || []
                                                            conditions[indexCondition].field1 = newValue
                                                            campos[index].conditions = conditions
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...configuracion_salida || {},
                                                                    campos
                                                                }
                                                            })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Campo 2" />}
                                                        style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* AGREGAR CONDICION */}
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    style={{ margin: "1em 0.5em 1em 0em" }}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        const campos = configuracion_salida?.campos || []
                                        const conditions = campos[index]?.conditions || []
                                        conditions.push({
                                            field0: null,
                                            field1: null,
                                            condition: 5,
                                        })
                                        campos[index].conditions = conditions
                                        onSelectedConfChange({
                                            configuracion_salida: {
                                                ...configuracion_salida || {},
                                                campos
                                            }
                                        })
                                    }}
                                >
                                    Condición
                                </Button>
                            </div>
                        </div>
                        {/* CAMPOS DATA 1 */}
                        {campo.campos?.map((_campo, indexCampo) => {
                            return (
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '1em 0em 0em 0em' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {/* CANCEL ICON */}
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                            <CancelIcon
                                                style={{ alignSelf: 'center', cursor: 'pointer' }}
                                                onClick={() => {
                                                    const campos = configuracion_salida?.campos || []
                                                    const _campos = campos[index].campos || []
                                                    _campos.splice(indexCampo, 1)
                                                    campos[index].campos = _campos
                                                    onSelectedConfChange({
                                                        configuracion_salida: {
                                                            ...configuracion_salida || {},
                                                            campos
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: "0.5em" }}>
                                                {/* NOMBRE */}
                                                <div style={{ display: 'flex', width: "50%" }}>
                                                    <EditableText
                                                        spanBorder={true}
                                                        spanStyle={{ cursor: 'text', fontSize: '1em', fontWeight: 'bold' }}
                                                        containerStyle={{ margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                        inline={false}
                                                        initialText={_campo?.nombre}
                                                        placeholder="Nombre"
                                                        onChange={(event) => {
                                                            const campos = configuracion_salida?.campos || []
                                                            const _campos = campos[index].campos || []
                                                            _campos[indexCampo].nombre = event.target.value
                                                            campos[index].campos = _campos
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...configuracion_salida || {},
                                                                    campos
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {/* FIELD 1 */}
                                                <div style={{ display: 'flex', width: "50%" }}>
                                                    <Autocomplete
                                                        id={`campo-campo-${index}-field1`}
                                                        options={app[`conector:${campo?.data1}`]?.campos || []}
                                                        value={(app[`conector:${campo?.data1}`]?.campos || []).find((field) => field === _campo?.valor) || null}
                                                        getOptionLabel={(option) => option || ''}
                                                        getOptionKey={(option) => option || ''}
                                                        onChange={(event, newValue) => {
                                                            const campos = configuracion_salida?.campos || []
                                                            const _campos = campos[index].campos || []
                                                            _campos[indexCampo].valor = newValue
                                                            _campos[indexCampo].nombre = newValue
                                                            campos[index].campos = _campos
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...configuracion_salida || {},
                                                                    campos
                                                                }
                                                            })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Campo 2" />}
                                                        style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* AGREGAR CAMPOS */}
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    style={{ margin: "1em 0.5em 1em 0em" }}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        const campos = configuracion_salida?.campos || []
                                        const _campos = campos[index]?.campos || []
                                        _campos.push({
                                            nombre: null,
                                            valor: null,
                                        })
                                        campos[index].campos = _campos
                                        onSelectedConfChange({
                                            configuracion_salida: {
                                                ...configuracion_salida || {},
                                                campos
                                            }
                                        })
                                    }}
                                >
                                    Campo
                                </Button>
                            </div>
                        </div>
                    </>
                    {/* Seleccion de OPERADOR */}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', padding: "1em 1em 1em 0em", width: "100%", justifyContent: 'flex-start' }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Operador</FormLabel>
                                <RadioGroup
                                    row
                                    value={campo.operador}
                                    onChange={(event) => {
                                        const newCampos = configuracion_salida?.campos || []
                                        newCampos[index].operador = event.target.value
                                        onSelectedConfChange({
                                            configuracion_salida: {
                                                ...configuracion_salida || {},
                                                campos: newCampos
                                            }
                                        })
                                    }}
                                >
                                    {join_operators?.map((operator) => {
                                        return (
                                            <FormControlLabel value={operator.value} control={<Radio />} label={operator.nombre} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Join