export const converToPixels = (value, asNumber = false) => {
    if (typeof value === "string") {
        if (value.includes("px")) {
            value.replace('px', '')
        } else if (value.includes("em")) {
            value = parseFloat(value.replace('em', '')) * 16
        }
    }

    return asNumber ? value : `${value}px`
}

export const cronTranslation = {
    "Every": "Cada",
    "day(s)": "día(s)",
    "Every week day": "De lunes a viernes",
    "Start time": "Hora de inicio",
    "hour": "hora",
    "At": "A las",
    "minute(s)": "minuto(s)",
    "Day": "Día",
    "of every month(s)": "de cada mes",
    "Last day of every month": "Último día de cada mes",
    "On the last weekday of every month": "En el último día de la semana del mes",
    "day(s) before the end of the month": "día(s) antes del final del mes",
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Minutes": "Minutos",
    "Hourly": "Horas",
    "Daily": "Días",
    "Weekly": "Semanas",
    "Monthly": "Meses",
    "Custom": "Personalizado",
    "Expression": "Expresión",
}

export const routes_conditions = [
    {
        nombre: "Mayor que",
        value: 1,
        hint: "El valor es mayor al campo (numérico)."
    },
    {
        nombre: "Menor que",
        value: 2,
        hint: "El valor es menor al campo (numérico)."
    },
    {
        nombre: "Mayor o igual que",
        value: 3,
        hint: "El valor es mayor o igual al campo (numérico)."
    },
    {
        nombre: "Menor o igual que",
        value: 4,
        hint: "El valor es menor o igual al campo (numérico)."
    },
    {
        nombre: "Igual a",
        value: 5,
        hint: "El valor es igual al campo."
    },
    {
        nombre: "Diferente a",
        value: 6,
        hint: "El campo no es igual al valor."
    },
]

export const routes_operators = [
    {
        "nombre": "AND",
        "value": 1,
    },
    {
        "nombre": "OR",
        "value": 2,
    }
]

export const join_operators = [
    {
        "nombre": "INNER",
        "value": 'inner',
    },
    {
        "nombre": "LEFT",
        "value": 'left',
    },
    {
        "nombre": "RIGHT",
        "value": 'right',
    },
    {
        "nombre": "OUTER",
        "value": 'outer',
    },
]