import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from './utils/context/authContext';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ReactFlowProvider } from 'reactflow'
import IconButton from '@mui/material/IconButton';
import IconClose from '@mui/icons-material/Close';
import App from './App';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#14313d',
      main: '#1D4758',
      light: '#4a6b79',
    },
    secondary: {
      dark: '#3c003c',
      main: '#570057',
      light: '#783378',
    },
    white: {
      dark: '#FFFFFF',
      main: '#FFFFFF',
      light: '#FFFFFF',
    }
  },
});

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} color='white'>
      <IconClose />
    </IconButton>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            // persist 
            autoHideDuration={20000} // 20 segundos
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <ReactFlowProvider>
              <App />
            </ReactFlowProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
