import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Outlet } from 'react-router-dom';
import style from './style.module.css';
import CssBaseline from "@mui/material/CssBaseline";
import { AuthContext } from '../../utils/context/authContext';
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../utils/api'
import { useSnackbar } from 'notistack';

const pages = ['Products', 'Pricing', 'Blog'];

function MainAppBar() {
  const { enqueueSnackbar: Alert } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { getUsuario, getNombre, logout } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const settings = [
    {
      name: 'Profile',
      onClick: () => { }
    },
    {
      name: 'Logout',
      onClick: () => {
        logout();
        navigate('/login');
      }
    }
  ];

  const handleClosePassword = () => {
    setOpenPassword(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  const submitPassword = (e) => {
    e?.preventDefault();
    if (currentPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
      setLoading(true);
      api.Post('usuario/changePassword', {
        currentPassword,
        newPassword,
      })
        .then((response) => {
          const { data } = response;
          const { content } = data;
          Alert(content, { variant: 'success' });
          handleClosePassword();
        })
        .catch((e) => {
          const { name, message, code } = e || {};
          const { data } = e?.response || {};
          const { content } = data || {};
          Alert(`${name} [${code}]: ${content || message}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Alert('Complete todos los campos', { variant: 'error' });
    }
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar position="static" className={style.appbar}>
        <Container maxWidth="xxl" disableGutters >
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {/* <AdbIcon sx={{ display: 'flex', mr: 1 }} /> */}
              <img src='/ILU.svg' 
                className={style.logoilu} 
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
              />
              {/* <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  display: 'flex',
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Conector Universal
              </Typography> */}

            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginRight: '2rem' }}>
                  <Typography sx={{ lineHeight: 1 }} variant='button'>{getNombre()}</Typography>
                  <Typography sx={{ lineHeight: 1 }} variant='caption' color='#B6B4B4'>{getUsuario()}</Typography>
                  <Typography
                    sx={{ lineHeight: 1, marginTop: "0.5em", cursor: 'pointer' }}
                    variant='caption'
                    color='#B6B4B4'
                    onClick={() => {
                      setOpenPassword(true);
                    }}
                  >
                    Cambiar contraseña
                  </Typography>
                </div>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={getNombre()} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
              </div>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={setting.onClick}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
          {/* Dialogo de cambio de contraseña */}
          <Dialog onClose={handleClosePassword} open={openPassword}>
            <DialogTitle>Cambiar contraseña</DialogTitle>
            <DialogContent>
              <form style={{ padding: "1em" }} onSubmit={submitPassword}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type='password'
                      id="contraseña-actual"
                      label="Contraseña actual"
                      variant="outlined"
                      value={currentPassword}
                      onChange={(event) => setCurrentPassword(event.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type='password'
                      id="nueva-contraseña"
                      label="Nueva contraseña"
                      variant="outlined"
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type='password'
                      id="confirmar-contraseña"
                      label="Confirmar contraseña"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      error={confirmPassword && newPassword !== confirmPassword}
                      helperText={confirmPassword && newPassword !== confirmPassword ? 'Las contraseñas no coinciden' : ''}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button variant="text" color='primary' onClick={handleClosePassword}>Cancelar</Button>
                    <Button variant="contained" color='primary' type='submit'>Cambiar</Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
          {/* Pantalla de carga */}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </AppBar>
      <div className={style.content}>
        <Outlet />
      </div>
    </Box>
  );
}
export default MainAppBar;