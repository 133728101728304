import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import Home from './pages/home';
import CrearFlujo from './pages/flujo/crear'
import CrearMaestro from './pages/maestro/crear'
import ProtectedRoute from './utils/protectedRoute';
import MainAppBar from './components/appBar';

const App = () => {
  return (
    <Routes>
      {/* Componente padre, cambiar luegooo */}
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MainAppBar />
          </ProtectedRoute>
        }
      >
        <Route index element={<Home />} />
        <Route path='flujo'>
          <Route path=':id' element={<CrearFlujo/>}/>
        </Route>
        {/* <Route path='maestro'>
          <Route path='crear' element={<CrearMaestro/>}/>
          <Route path=':id'>
            <Route path='ver' element={<CrearMaestro/>}/>
            <Route path='editar' element={<CrearMaestro/>}/>
          </Route>
        </Route> */}
      </Route>
      <Route path="*" element={<Login />} />
    </Routes>
  )
}

export default App;
