import React from 'react';
import Flujo from '../flujo'
import Bitacora from '../bitacora';
import Maestro from '../maestro';
import HojaS3 from '../hojaS3';
import Tarea from '../tarea';
import MainTabs from '../../components/mainTabs';

const Login = () => {
  return (
    <div>
      <MainTabs
        flujosComponent={<Flujo/>}
        tareasComponent={<Tarea/>}
        // maestrosComponent={<Maestro/>}
        origenesComponent={<div>Origenes</div>}
        destinosComponent={<div>Destinos</div>}
        bitacoraComponent={<Bitacora/>}
        hojaS3Component={<HojaS3/>}
      />
    </div>
  )
}

export default Login;