import React, { useCallback, useState } from "react";
import SourceIcon from '@mui/icons-material/Source';
import AdjustIcon from '@mui/icons-material/Adjust';
import CodeIcon from '@mui/icons-material/Code';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import PlayIcon from '@mui/icons-material/PlayCircleTwoTone';
import PauseIcon from '@mui/icons-material/PauseTwoTone';
import CalendarIcon from '@mui/icons-material/EventTwoTone';
import SaveIcon from '@mui/icons-material/SaveTwoTone';
import DifferenceIcon from '@mui/icons-material/Difference';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import TextField from '@mui/material/TextField';
import api from '../../../utils/api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AutoSizer, Grid as GridVirtualized, List, Column as ColumnVirtualized, Table as TableVirtulized } from 'react-virtualized';
import EditableText from "../../../components/editableText";
import { DraggableBox, DropContainer } from "../../../components/draggable";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AppConfigGenerator } from '../../../utils/app-config-generator'
import 'react-virtualized/styles.css';

import ReactFlow, { MarkerType, Background, Controls, addEdge, applyEdgeChanges, applyNodeChanges, useReactFlow } from 'reactflow';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import 'reactflow/dist/style.css';
import Conector, { ConectorAccion, TIPO_ACCION, conectorWidth } from "./conector";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Concat, Rename, Script, Router, Join } from "./actionOutput";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import FullScreenIcon from '@mui/icons-material/FullscreenTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Cron, { HEADER } from 'react-cron-generator'
import { cronTranslation, converToPixels } from "../../../utils/constants";
// import 'react-cron-generator/dist/cron-builder.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Autocomplete from '@mui/material/Autocomplete';
import Settings from "./settings";
import moment from "moment";

import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
    Item,
    Grouping,
    GroupPanel,
    StateStoring,
    ColumnChooser,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import 'devextreme/dist/css/dx.light.css';

import HojaS3 from '../../hojaS3'
import S3Files from "./destino/s3_files";

// COMPONENTES ORIGEN
import OrigenApi from "./origen/api";

const tipoOrigenDict = {
    db: "Base de datos",
    bd: "Base de datos",
    api: "API",
    s3: "S3",
    maestro: "Maestros",
    response: "Respuesta",
    otro: "Otro",
}

const tipos = {
    db: 1,
    bd: 1,
    api: 2,
    s3: 3,
    maestro: 4,
    response: 5,
}

// TIPOS DE CONECTOR
const CONECTOR = {
    ORIGEN: 1,
    ACCION: 2,
    DESTINO: 3
}

const CrearFlujo = () => {

    const { enqueueSnackbar: Alert } = useSnackbar();

    const params = useParams()

    const [negativePadding, setNegativePadding] = React.useState(window.innerWidth <= 900 ? 16 : 48)

    const [nodeTypes, setNodeTypes] = React.useState({})

    const [conectores, setConectores] = React.useState([])

    const [newConnector, setNewConnector] = React.useState({ open: false })

    const [edges, setEdges] = useState([]);

    const [Fullscreen, setFullscreen] = useState({
        open: false,
        title: '',
        Component: React.Fragment
    });

    const [flujo, setFlujo] = React.useState({})
    const [origenConf, setOrigenConf] = React.useState({})
    const [destinoConf, setDestinoConf] = React.useState({})
    const [selectedConf, setSelectedConf] = React.useState(null)
    const [focusedConector, setFocusedConector] = React.useState(null)
    const [origenAttrs, setOrigenAttrs] = React.useState({})
    const [destinoAttrs, setDestinoAttrs] = React.useState({})
    const [accionAttrs, setAccionAttrs] = React.useState({})

    const [origenes, setOrigenes] = React.useState([])
    const [origenesAll, setOrigenesAll] = React.useState({})
    const [tipoOrigenes, setTipoOrigenes] = React.useState([])

    const [destinos, setDestinos] = React.useState([])
    const [destinosAll, setDestinosAll] = React.useState({})
    const [tipoDestinos, setTipoDestinos] = React.useState([])

    const [loading, setLoading] = React.useState(false)
    const [actionsOpened, setActionsOpened] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null); // para el menu de acciones
    const [executionLog, setExecutionLog] = React.useState(null)
    const [tasks, setTasks] = React.useState([])
    const [tasksOpen, setTasksOpen] = React.useState(false)
    const [settingsOpen, setSettingsOpen] = React.useState(false)
    const [settings, setSettings] = React.useState({})
    const [tags, setTags] = React.useState([])

    const [openS3Dialog, setOpenS3Dialog] = React.useState(false)
    const [S3destinoDialog, setS3DestinoDialog] = React.useState({
        open: false,
        files: []
    })

    const reactFlow = useReactFlow();

    function closeFullScreen() {
        setFullscreen({
            open: false,
            title: '',
            Component: React.Fragment
        })
    }

    const handleCloseSettings = () => {
        setSettingsOpen(false)
    }

    // Funciones por defecto de React Flow
    const onNodesChange = useCallback(
        (changes) => setConectores((nds) => applyNodeChanges(changes, nds)),
        [setConectores]
    );
    // cuando los nodos sufren cambios (seleccion o eliminacion)
    const onEdgesChange = useCallback(
        (changes) => {
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [setEdges]
    );
    // Cuando se conectan los nodos
    const onConnect = useCallback(
        (connection) => {
            setEdges((eds) => addEdge({
                ...connection,
                type: 'smoothstep',
                markerEnd: { // flecha
                    type: MarkerType.ArrowClosed,
                    color: 'black',
                },
                style: { // linea
                    strokeWidth: 2,
                    stroke: 'black'
                }
            }, eds))
        },
        [setEdges]
    );

    function getFlowTasks() {
        setLoading(true)
        setTasksOpen(true)
        api.Get(`flujo/${params.id}/tasks/`)
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setTasks(content)
            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function saveFlowTasks() {
        setLoading(true)
        api.Post(`flujo/${params.id}/save_tasks/`, { tasks })
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setTasks(content)
                Alert(`${detail}: Tareas actualizadas correctamente.`, { variant: 'success' })
            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function get_secret_data(secret_arn, source = 'origen', conector = null, params = {}) {
        setLoading(true)
        // Se obtienen la data de ese secret
        var response = {}
        try {
            response = await api.Get(`${source}/get_${source}_data`, {
                conector,
                secret_arn,
                flujo: params.id,
                ...params,
                reload_cache: !flujo?.use_cache,
            })
        } catch (e) {
            response = e.response
        }
        const { data, status } = response || {}
        const { detail, content } = data || {}
        if (detail == 'error') {
            setSelectedConf(null)
            Alert(`${detail}: ${content}`, { variant: 'error' })
        }
        setLoading(false)
        return data
    }

    const actionTooltipOut = () => {
        const action = selectedConf?.data?.tipo_accion
        var value = ''
        switch (action) {
            case TIPO_ACCION.CONCAT:
                value = `Nombre del campo | Campos de origen | Separador`
                break;
            case TIPO_ACCION.RENAME:
                value = `Nombre del campo | Campos de origen | Conservar origen`
                break;
            default:
                break;
        }
        return value
    }

    function setSourceConf(conf) {
        const { data, id } = conf || {}
        setSelectedConf(conf)
        const { setCenter } = reactFlow
        setFocusedConector(id)
        const el = document.getElementById(id)
        const x = (conf?.position?.x || 0) + (el?.offsetWidth / 2)
        const y = (conf?.position?.y || 0) + (el?.offsetHeight / 2)
        const zoom = 1.85
        setTimeout(() => {
            setCenter(x, y, { zoom, duration: 1000 })
        }, 0);
        switch (data.accion) {
            case CONECTOR.ORIGEN:
                if (data?.tipoSource) setOrigenes(origenesAll[data.tipoSource])
                const params1 = {}
                if (data?.tipoSource == 'maestro') {
                    params1['idmaestro'] = data?.idmaestro
                    params1['filtromaestro'] = data?.filtromaestro
                }
                get_secret_data(data?.secret_arn, 'origen', id, params1)
                    .then(response => {
                        const { detail, content } = response || {}
                        if (detail == 'success') setOrigenAttrs(content)
                    })
                break;
            case CONECTOR.DESTINO:
                if (data.tipoSource) setDestinos(destinosAll[data.tipoSource])
                if (data.secret_arn) {
                    get_secret_data(data.secret_arn, 'destino', id)
                        .then(response => {
                            const { detail, content } = response || {}
                            if (detail == 'success') setDestinoAttrs(content)
                        })
                }
                break;
            case CONECTOR.ACCION:
                const params = {
                    get_librerias: data.tipo_accion == TIPO_ACCION.SCRIPT,
                    get_targets: data.tipo_accion == TIPO_ACCION.ROUTER,
                    get_sources: data.tipo_accion == TIPO_ACCION.JOIN,
                }
                if (data?.configuracion_entrada?.sources?.length) {
                    if (data.tipoSource) setDestinos(destinosAll[data.tipoSource])
                    get_secret_data(data.secret_arn, 'destino', id, params)
                        .then(response => {
                            const { detail, content } = response || {}
                            if (detail == 'success') setAccionAttrs(content)
                        })
                }
                break;
            default:
                break;
        }
    }

    function setFullFlujo(flujo, origen, destino, edges, conectores) {
        setFlujo(flujo)
        setOrigenConf(origen)
        setDestinoConf(destino)
        setEdges(edges)
        setConectores(conectores)
        if (selectedConf) {
            setSelectedConf(conectores?.filter(conector => conector.id == selectedConf.id)[0] || null)
        }
    }

    // Pasar props actualizadas a los tipos de nodo
    function updateNodesProps() {
        // Se pasan props actualizadas
        setNodeTypes({
            conector: (props) => {
                return (
                    <Conector
                        {...props}
                        setSourceConf={setSourceConf}
                        setLoading={setLoading}
                        setFullFlujo={setFullFlujo}
                        isFocused={focusedConector == props.id}
                    />
                )
            },
            accion: (props) => {
                return (
                    <ConectorAccion
                        {...props}
                        setSourceConf={setSourceConf}
                        setLoading={setLoading}
                        setFullFlujo={setFullFlujo}
                        isFocused={focusedConector == props.id}
                    />
                )
            }
        })
    }

    const init = async () => {
        var response = null, detail = null, content = null
        setLoading(true)
        try {
            // Se obtienen los tags disponibles
            response = await api.Get('flujo/get_tags')
            if (response.status == 200) {
                content = response.data.content
                setTags(content || [])
            } else {
                detail = response.data.detail
                content = response.data.content
                Alert(`${detail}: ${content}`, { variant: 'error' })
            }
            // Se obtienen los origenes disponibles
            response = await api.Get('origen_destino/get_secret_origenes')
            if (response.status == 200) {
                content = response.data.content
                setOrigenesAll(content || {})

                const tempTipoOrigenes = []

                Object.keys(content || {}).forEach(key => {
                    tempTipoOrigenes.push({
                        value: key,
                        label: !tipoOrigenDict[key] ? key : tipoOrigenDict[key]
                    })
                })

                setTipoOrigenes(tempTipoOrigenes)
            } else {
                detail = response.data.detail
                content = response.data.content
                Alert(`${detail}: ${content}`, { variant: 'error' })
            }
            // Se obtienen los destinos disponibles
            response = await api.Get('origen_destino/get_secret_destinos')
            if (response.status == 200) {
                content = response.data.content
                setDestinosAll(content || {})

                const tempTipoDestinos = []

                Object.keys(content || {}).forEach(key => {
                    tempTipoDestinos.push({
                        value: key,
                        label: !tipoOrigenDict[key] ? key : tipoOrigenDict[key]
                    })
                })

                setTipoDestinos(tempTipoDestinos)
            } else {
                detail = response.data.detail
                content = response.data.content
                Alert(`${detail}: ${content}`, { variant: 'error' })
            }
            // Se obtiene la información del flujo
            response = await api.Get(`flujo/${params.id}`)
            if (response.status == 200) {
                content = response.data
                setFullFlujo(
                    content || {},
                    content?.origen || {},
                    content?.destino || {},
                    content?.edges || [],
                    content?.conectores || []
                )
            } else {
                detail = response.data.detail
                content = response.data.content
                Alert(`${detail}: ${content}`, { variant: 'error' })
            }
        } catch (e) {
            const { response } = e || {}
            const { data, status } = response || {}
            const { detail, content } = data || {}
            Alert(`${detail}: ${content}`, { variant: 'error' })
        }
        setLoading(false)
    }

    const save_conector = (accion) => {
        setLoading(true)
        const payload = {}
        const acciones = {
            [CONECTOR.ORIGEN]: 'origen',
            [CONECTOR.ACCION]: 'accion',
            [CONECTOR.DESTINO]: 'destino',
        }
        payload['save_' + acciones[accion]] = true
        payload.nombre = flujo.nombre
        payload.descripcion = flujo.descripcion
        payload.edges = edges
        payload[acciones[accion]] = selectedConf || {}
        api.Put(`flujo/${flujo.id}/`, payload)
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: Flujo actualizado correctamente.`, { variant: 'success' })
            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function get_new_position() {
        // Crear un conjunto para almacenar las coordenadas ocupadas
        const coordenadasOcupadas = new Set();

        // Recorrer la lista de elementos y agregar las coordenadas ocupadas al conjunto
        conectores.forEach(conector => {
            const html = document.getElementById(conector.id);
            const positionInfo = html.getBoundingClientRect();
            const height = positionInfo.height;
            const width = positionInfo.width;
            for (let i = conector.position.x; i < conector.position.x + width; i++) {
                for (let j = conector.position.y; j < conector.position.y + height; j++) {
                    coordenadasOcupadas.add(`${i},${j}`);
                }
            }
        });

        // Encontrar las coordenadas libres
        for (let x = 0; ; x++) {
            for (let y = 0; ; y++) {
                let coordenadasLibres = true;

                // Verificar si las coordenadas están ocupadas
                for (let i = x; i < x + 320; i++) {
                    for (let j = y; j < y + 114; j++) {
                        if (coordenadasOcupadas.has(`${i},${j}`)) {
                            coordenadasLibres = false;
                            break;
                        }
                    }
                    if (!coordenadasLibres) {
                        break;
                    }
                }

                if (coordenadasLibres) {
                    // Coordenadas libres encontradas
                    return { x, y };
                }
            }
        }
    }

    // Para actualizar los props de los nodos
    React.useEffect(() => {
        updateNodesProps()
    }, [origenesAll, destinosAll, focusedConector, flujo])

    React.useEffect(() => {
        init()
    }, [])

    // pasar la configuracion en curso a la fuente que pertenezca
    React.useEffect(() => {
        if (selectedConf) {
            setConectores(
                conectores.map(conector => {
                    if (conector.id == selectedConf.id) {
                        conector.data = selectedConf.data
                    }
                    return conector
                })
            )
        }
        if (!selectedConf) {
            setTimeout(() => {
                setFocusedConector(null)
                reactFlow.fitView({ duration: 1000 })
            }, 0)
        }

    }, [selectedConf])

    // Para darle estructura al body final
    React.useEffect(() => {
        var body = {}, forEach = selectedConf?.data?.temp_body?.forEach
        // delete destinoConf.temp_body?.forEach
        if (forEach && destinoAttrs?.app) {
            // se integran los que no tienen onSameObj
            destinoAttrs?.app['input-fields']?.filter(campo => !campo.onSameObj && campo.forEach && destinoAttrs.app[campo.forEach]).forEach((campo) => {
                destinoAttrs.app[campo.forEach]?.forEach(valor_origen => {
                    body = {
                        ...body,
                        [valor_origen]: {
                            ...body[valor_origen] || {},
                            forEach: campo.forEach,
                            [campo.nombre]: forEach[campo.forEach][valor_origen][campo.nombre]
                        }
                    }
                })
            })
            // se integran los que tienen onSameObj
            destinoAttrs?.app['input-fields']?.filter(campo => campo.onSameObj && campo.forEach && destinoAttrs.app[campo.forEach]).forEach((campo, index) => {
                destinoAttrs?.app[campo.forEach]?.forEach(valor_origen => {
                    if (!body[campo.forList || campo.forEach] || !body[campo.forList || campo.forEach].filter(item => item[campo.forEachValueKey || campo.forEach] == valor_origen).length) {
                        body = {
                            ...body,
                            [campo.forList || campo.forEach]: [
                                {
                                    [campo.forEachValueKey || campo.forEach]: valor_origen,
                                    forEach: campo.forEach,
                                    ...destinoAttrs.app['input-fields'].filter(campo => campo.onSameObj && campo.forEach && destinoAttrs.app[campo.forEach])
                                        .map(item => item.name).reduce((obj, value) => ({ ...obj, [value]: forEach[campo.forEach][valor_origen][value] }), {})
                                }
                            ].concat(body[campo.forList || campo.forEach] || [])
                        }
                    }
                })
            })
        }
        body = {
            ...body,
            ...Object.assign(
                {},
                ...Object.keys(selectedConf?.data?.temp_body || {})
                    .filter(key => !['forEach'].includes(key))
                    .map(key => ({ [key]: selectedConf?.data?.temp_body[key] }))
            )
        }
        if (Object.keys(body).length && selectedConf) onSelectedConfChange({ body })
    }, [selectedConf?.data?.temp_body])

    const actions = [
        {
            icon: <SourceIcon />,
            name: 'Origen',
            onClick: () => {
                setNewConnector({
                    open: true,
                    type: 'origen',
                    config: {
                        type: 'conector',
                        position: get_new_position(),
                        data: {
                            flujo: params.id,
                            es_destino: false,
                            secret_arn: null,
                            query: "",
                            accion: CONECTOR.ORIGEN,
                            targets: []
                        }
                    }
                })
            }
        },
        {
            icon: <AdjustIcon />,
            name: 'Destino',
            onClick: () => {
                setNewConnector({
                    open: true,
                    type: 'destino',
                    config: {
                        type: 'conector',
                        position: get_new_position(),
                        data: {
                            flujo: params.id,
                            es_destino: true,
                            secret_arn: null,
                            accion: CONECTOR.DESTINO,
                            sources: []
                        }
                    }
                })
            }
        },
        {
            icon: <CodeIcon />,
            name: 'Acción',
            actions: [
                {
                    icon: <DifferenceIcon />,
                    name: 'Concatenar',
                    onClick: () => {
                        setNewConnector({
                            open: true,
                            type: 'acción',
                            disabled: true,
                            config: {
                                type: 'accion',
                                position: get_new_position(),
                                data: {
                                    flujo: params.id,
                                    es_destino: false,
                                    secret_arn: null,
                                    accion: CONECTOR.ACCION,
                                    nombre: "CONCATENAR CAMPOS",
                                    tipo_accion: TIPO_ACCION.CONCAT,
                                    configuracion_entrada: {
                                        sources: [],
                                        secret_arn: null,
                                    },
                                    configuracion_salida: {
                                        targets: [],
                                        include_origin_fields: true, // determina si en la salida se desean los campos del origen + nuevos
                                    },
                                }
                            }
                        })
                    }
                },
                {
                    icon: <RenameIcon />,
                    name: 'Renombrar',
                    onClick: () => {
                        setNewConnector({
                            open: true,
                            type: 'acción',
                            disabled: true,
                            config: {
                                type: 'accion',
                                position: get_new_position(),
                                data: {
                                    flujo: params.id,
                                    es_destino: false,
                                    secret_arn: null,
                                    accion: CONECTOR.ACCION,
                                    nombre: "RENOMBRAR CAMPOS",
                                    tipo_accion: TIPO_ACCION.RENAME,
                                    configuracion_entrada: {
                                        sources: [],
                                        secret_arn: null,
                                    },
                                    configuracion_salida: {
                                        targets: [],
                                        include_origin_fields: true, // determina si en la salida se desean los campos del origen + nuevos
                                    },
                                }
                            }
                        })
                    }
                },
                {
                    icon: <CodeIcon />,
                    name: 'PyScript',
                    onClick: () => {
                        setNewConnector({
                            open: true,
                            type: 'acción',
                            disabled: true,
                            config: {
                                type: 'accion',
                                position: get_new_position(),
                                data: {
                                    flujo: params.id,
                                    es_destino: false,
                                    secret_arn: null,
                                    accion: CONECTOR.ACCION,
                                    nombre: "PYSCRIPT",
                                    tipo_accion: TIPO_ACCION.SCRIPT,
                                    configuracion_entrada: {
                                        sources: [],
                                        secret_arn: null,
                                    },
                                    configuracion_salida: {
                                        targets: [],
                                        include_origin_fields: true, // determina si en la salida se desean los campos del origen + nuevos
                                        lang: 'python',
                                        code: 'import pandas as pd\n\ndef data_handler(data: pd.DataFrame(), *args, **kwargs):\n\t' +
                                            '# Invocar data secundaria\n\t# dataN = kwargs.get(\'dataN\', pd.DataFrame())\n\t' +
                                            'new_data = [] # Lista de diccionarios\n\t# Your code here\n\t\n\treturn new_data',
                                    },
                                }
                            }
                        })
                    }
                },
                {
                    icon: <ForkRightIcon />,
                    name: 'Router',
                    onClick: () => {
                        setNewConnector({
                            open: true,
                            type: 'acción',
                            disabled: true,
                            config: {
                                type: 'accion',
                                position: get_new_position(),
                                data: {
                                    flujo: params.id,
                                    es_destino: false,
                                    secret_arn: null,
                                    accion: CONECTOR.ACCION,
                                    nombre: "ROUTER",
                                    tipo_accion: TIPO_ACCION.ROUTER,
                                    configuracion_entrada: {
                                        sources: [],
                                        secret_arn: null,
                                    },
                                    configuracion_salida: {
                                        targets: [],
                                        include_origin_fields: true, // determina si en la salida se desean los campos del origen + nuevos
                                        routes: [{
                                            nombre: 'default',
                                            default: true,
                                            target: null,
                                        }] // { nombre: '', conditions: [{ campo: '', condition: '', valor: '' }], target: '', operador: 'AND/OR, default: false }
                                    },
                                }
                            }
                        })
                    }
                },
                {
                    icon: <JoinInnerIcon />,
                    name: 'Join',
                    onClick: () => {
                        setNewConnector({
                            open: true,
                            type: 'acción',
                            disabled: true,
                            config: {
                                type: 'accion',
                                position: get_new_position(),
                                data: {
                                    flujo: params.id,
                                    es_destino: false,
                                    secret_arn: null,
                                    accion: CONECTOR.ACCION,
                                    nombre: "JOIN",
                                    tipo_accion: TIPO_ACCION.JOIN,
                                    configuracion_entrada: {
                                        sources: [],
                                        secret_arn: null,
                                    },
                                    configuracion_salida: {
                                        targets: [],
                                        include_origin_fields: true, // determina si en la salida se desean los campos del origen + nuevos
                                        data0: null, // fuente principal
                                        campos: [], // { nombre: '', data1: '', contional: '', field0: '', field1: '', 'valueSource': '', value: ''}
                                    },
                                }
                            }
                        })
                    }
                },
            ],
        },
    ];

    function save_new_conector() {
        setLoading(true)
        api.Post(`flujo/new_connector/`, newConnector)
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setConectores([...conectores, content])
                setSelectedConf(content)
                setNewConnector({ open: false })
            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function onSelectedConfChange(newConf) {
        setSelectedConf({
            ...selectedConf,
            data: {
                ...selectedConf.data,
                ...newConf
            }
        })
    }

    function created_body_changes(composed_key, value) {
        var temp_body = selectedConf.data.temp_body
        var niveles = composed_key?.split('__') || [];

        niveles.reduce((obj, key, index) => {
            if (index === niveles.length - 1) {
                obj[key] = value;
            } else {
                return obj[key];
            }
        }, temp_body);

        onSelectedConfChange({ temp_body })
    }

    function testQuery(secret_arn, query) {
        setLoading(true)
        api.Post('origen/test_origen_query/', {
            flujo: params.id,
            secret_arn,
            query
        }, false, { reload_cache: !flujo?.use_cache })
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setOrigenAttrs({
                    ...origenAttrs,
                    ...content,
                    query_test: 'Query exitoso.'
                })

            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setOrigenAttrs({
                    ...origenAttrs,
                    query_test: 'Error en el query.',
                    campos: null,
                    resultados: null
                })
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function testS3(s3_path, s3_delimiter, s3_decode = 'utf-8') {
        setLoading(true)
        api.Post('origen/test_origen_s3/', {
            s3_path,
            s3_delimiter,
            s3_decode,
            top: 50,
        })
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setOrigenAttrs({
                    ...origenAttrs,
                    ...content,
                    dialog: true,
                    s3_test: 'S3 exitoso.'
                })

            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setOrigenAttrs({
                    ...origenAttrs,
                    query_test: 'Error en el S3.',
                    campos: null,
                    resultados: null
                })
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function saveFlujoGeneral() {
        setLoading(true)
        api.Put(`flujo/${params.id}/general/`, { flujo, edges, conectores })
            .then(response => {
                const { data, status } = response || {}
                const { detail, content } = data || {}
                setEdges(content?.edges || [])
                setConectores((content?.conectores || []))
                if (selectedConf) {
                    setSelectedConf(content?.conectores?.filter(conector => conector.id == selectedConf.id)[0] || null)
                }
                Alert(`${detail}: Flujo actualizado correctamente.`, { variant: 'success' })
            })
            .catch(e => {
                const { response } = e || {}
                const { data, status } = response || {}
                const { detail, content } = data || {}
                Alert(`${detail}: ${content}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function onSizeChange() {
        setNegativePadding((window.innerWidth <= 900 ? 16 : 48))
        setTimeout(() => {
            reactFlow.fitView(true)
        }, 0)
    }

    window.onresize = onSizeChange

    return (
        <React.Fragment>
            <div style={{ width: `100%`, display: 'flex', flexDirection: 'row' }}>
                {/* Header nombre del flujo */}
                <div style={{ display: 'flex', flexDirection: 'column', background: '#D9D9D9', flex: 1, justifyContent: 'center', width: '100%' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
                        <span>Flujo /&nbsp;</span>
                        <EditableText initialText={flujo.nombre} placeholder="Nombre del flujo" onChange={(event) => { setFlujo({ ...flujo, nombre: event.target.value }) }} />
                    </div>
                    <div style={{ display: 'flex', padding: '0em 0em 0em 1em' }}>
                        {actions.map((action, index) => {
                            return (
                                action.actions ?
                                    <div>
                                        <Button
                                            id="demo-positioned-button"
                                            aria-controls={actionsOpened ? 'demo-positioned-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={actionsOpened ? 'true' : undefined}
                                            onClick={(event) => {
                                                setAnchorEl(event.currentTarget);
                                                setActionsOpened(!actionsOpened)
                                            }}
                                            startIcon={action.icon}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            style={{ alignSelf: 'center', marginRight: '0.5em' }}
                                        >
                                            {action.name}
                                        </Button>
                                        <Menu
                                            id="demo-positioned-menu"
                                            aria-labelledby="demo-positioned-button"
                                            anchorEl={anchorEl}
                                            open={actionsOpened}
                                            onClose={() => setActionsOpened(false)}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {action.actions.map(subAction => {
                                                return (
                                                    <MenuItem onClick={subAction.onClick}>
                                                        <ListItemIcon>
                                                            {subAction.icon}
                                                        </ListItemIcon>
                                                        <span>{subAction.name}</span>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Menu>
                                    </div> :
                                    <Button
                                        color="primary"
                                        variant="text"
                                        onClick={action.onClick}
                                        startIcon={action.icon}
                                        style={{ alignSelf: 'center', marginRight: '0.5em' }}
                                    >
                                        {action.name}
                                    </Button>
                            )
                        })}
                        {/* Funcion de guardado */}
                        <div style={{ display: 'inline-block', alignSelf: 'center' }}>
                            <SaveIcon
                                color="primary"
                                sx={{ fontSize: 30, cursor: 'pointer', marginRight: '0.5em' }}
                                onClick={() => {
                                    saveFlujoGeneral()
                                }}
                            />
                            <CalendarIcon
                                color="primary"
                                sx={{ fontSize: 30, cursor: 'pointer', marginRight: '0.5em' }}
                                onClick={() => {
                                    getFlowTasks()
                                }}
                            />
                            <PlayIcon
                                color="primary"
                                sx={{ fontSize: 30, cursor: 'pointer', marginRight: '0.5em' }}
                                onClick={() => {
                                    setLoading(true)
                                    api.Get(`flujo/${params.id}/ejecutar/`)
                                        .then(response => {
                                            const { data, status } = response || {}
                                            const { detail, content } = data || {}
                                            const { log } = content || {}
                                            setExecutionLog(log)
                                            Alert(`${detail}: Flujo ejecutado correctamente.`, { variant: 'success' })
                                        })
                                        .catch(e => {
                                            const { response } = e || {}
                                            const { data, status } = response || {}
                                            const { detail, content } = data || {}
                                            Alert(`${detail}: ${content}`, { variant: 'error' })
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                }}
                            />
                            <SettingsIcon
                                color="primary"
                                sx={{ fontSize: 30, cursor: 'pointer', marginRight: '0.5em' }}
                                onClick={() => {
                                    setSettings({
                                        setLoading,
                                        handleClose: handleCloseSettings,
                                    })
                                    setSettingsOpen(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: `100%`, display: 'flex', flexDirection: 'row', maxHeight: 'calc(100vh - 150px)' }}>
                <Grid container spacing={0}>
                    {/* El flujo */}
                    <Grid item xs={selectedConf ? 8 : 12} md={selectedConf ? 8 : 12}>
                        <div style={{ height: 'calc(100vh - 150px)', width: '100%' }}>
                            <ReactFlow
                                nodes={conectores}
                                nodeTypes={nodeTypes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                setSelectedConf={setSelectedConf}
                                fitView
                            >
                                <Background />
                                <Controls />
                            </ReactFlow>
                        </div>
                    </Grid>
                    {/* Configuraciones de origen */}
                    {selectedConf?.data?.accion == CONECTOR.ORIGEN &&
                        <Grid item xs={4} md={4} style={{ background: "#D9D9D9", padding: "0em 0em 1em 0em", height: 'calc(100vh - 150px)', overflow: 'auto' }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "2em 2em 2em 2em", width: "100%" }}>
                                    <TextField
                                        required
                                        id="origenName"
                                        label="Nombre"
                                        value={selectedConf.data.nombre}
                                        onChange={(e) => onSelectedConfChange({ nombre: e.target.value })}
                                        style={{ width: "100%", background: "white" }}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "0em 2em 1em 2em", width: "100%" }}>
                                    <TextField
                                        required
                                        id="origenDescripcion"
                                        label="Descripción"
                                        multiline
                                        maxRows={4}
                                        value={selectedConf.data.descripcion}
                                        onChange={(e) => onSelectedConfChange({ descripcion: e.target.value })}
                                        style={{ width: "100%", background: "white" }}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "0em 1em 1em 2em", width: "50%" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tipo-origen-label">Tipo de origen</InputLabel>
                                        <Select
                                            labelId="tipo-origen-label"
                                            id="tipo-origen"
                                            value={selectedConf.data.tipoSource}
                                            label="Tipo de origen"
                                            onChange={(e) => {
                                                const value = e.target.value
                                                onSelectedConfChange({ tipoSource: value, secret_arn: null, tipo: tipos[value] })
                                                setOrigenes(origenesAll[value])
                                            }}
                                            style={{ width: "100%", background: "white" }}
                                        >
                                            {tipoOrigenes.map(tipo => {
                                                return (
                                                    <MenuItem value={tipo.value}>{tipo.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ padding: "0em 2em 1em 1em", width: "50%" }}>
                                    <FormControl fullWidth>
                                        {/* Se condiciona el select para los que tendran secrets */}
                                        {!['s3'].includes(selectedConf?.data?.tipoSource) &&
                                            <>
                                                <InputLabel id="origen-label">Origen</InputLabel>
                                                <Select
                                                    labelId="origen-label"
                                                    id="origen"
                                                    value={['db', 'bd', 'api'].includes(selectedConf.data.tipoSource) ? selectedConf?.data?.secret_arn :
                                                        selectedConf.data.tipoSource == 'maestro' ? selectedConf?.data?.idmaestro :
                                                            null}
                                                    label="Origen"
                                                    onChange={(e) => {
                                                        const value = e.target.value // ARN
                                                        setLoading(true)
                                                        // Se obtienen la data de ese secret
                                                        const key = ['db', 'bd', 'api'].includes(selectedConf.data.tipoSource) ? 'secret_arn' :
                                                            selectedConf.data.tipoSource == 'maestro' ? 'idmaestro' :
                                                                'none'
                                                        api.Get(`origen/get_origen_data`, {
                                                            [key]: value,
                                                            reload_cache: !flujo?.use_cache
                                                        })
                                                            .then(response => {
                                                                const { data, status } = response || {}
                                                                const { detail, content } = data || {}
                                                                setOrigenAttrs(content)
                                                                onSelectedConfChange({ [key]: value, query: "" })
                                                            })
                                                            .catch(e => {
                                                                const { response } = e || {}
                                                                const { data, status } = response || {}
                                                                const { detail, content } = data || {}
                                                                Alert(`${detail}: ${content}`, { variant: 'error' })
                                                            })
                                                            .finally(() => {
                                                                setLoading(false)
                                                            })
                                                    }}
                                                    style={{ width: "100%", background: "white" }}
                                                >
                                                    {origenes.map(origen => {
                                                        return (
                                                            <MenuItem value={origen.value}>{origen.label}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </>
                                        }
                                        {selectedConf?.data?.tipoSource == 's3' &&
                                            <>
                                                <TextField
                                                    required
                                                    id="origen"
                                                    label="S3 URI"
                                                    value={selectedConf.data.s3_path}
                                                    onChange={(e) => onSelectedConfChange({ s3_path: e.target.value })}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            {["s3"].includes(selectedConf.data.tipoSource) &&
                                <>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ padding: "0em 1em 1em 2em", width: "50%" }}>
                                            <FormControl fullWidth>
                                                {selectedConf?.data?.tipoSource == 's3' &&
                                                    <>
                                                        <TextField
                                                            id="s3-delimiter"
                                                            label="S3 delimitador"
                                                            value={selectedConf.data.s3_delimiter}
                                                            onChange={(e) => {
                                                                const value = e.target.value
                                                                if (value.length <= 1) {
                                                                    onSelectedConfChange({ s3_delimiter: value })
                                                                }
                                                            }}
                                                            style={{ width: "100%", background: "white" }}
                                                        />
                                                    </>
                                                }
                                            </FormControl>
                                        </div>
                                        <div style={{ padding: "0em 2em 1em 1em", width: "50%" }}>
                                            <FormControl fullWidth>
                                                {selectedConf?.data?.tipoSource == 's3' &&
                                                    <>
                                                        <TextField
                                                            id="s3-decode"
                                                            label="S3 decode"
                                                            value={selectedConf.data.s3_decode}
                                                            onChange={(e) => {
                                                                const value = e.target.value
                                                                onSelectedConfChange({ s3_decode: value })
                                                            }}
                                                            style={{ width: "100%", background: "white" }}
                                                        />
                                                    </>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ padding: "0em 2em 1em 2em", width: "100%", alignSelf: 'center' }}>
                                        <FormControl fullWidth>
                                            {selectedConf?.data?.tipoSource == 's3' &&
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            if (selectedConf?.data?.s3_path) {
                                                                testS3(selectedConf.data.s3_path, selectedConf.data.s3_delimiter, selectedConf.data.s3_decode)
                                                            }
                                                        }}
                                                    >
                                                        Previsualizar
                                                    </Button>
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", padding: "0em 2em 1em 2em", justifyContent: "center" }}>
                                        <Button
                                            style={{ width: "100%" }}
                                            variant="outlined"
                                            onClick={() => {
                                                setOpenS3Dialog(true)
                                            }}
                                        >
                                            Inspeccionar archivos MCU
                                        </Button>
                                    </div>
                                </>
                            }
                            {["maestro"].includes(selectedConf.data.tipoSource) && origenAttrs?.filter_by &&
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ padding: "0em 1em 1em 2em", width: "50%" }}>
                                        <FormControl fullWidth>
                                            {selectedConf?.data?.tipoSource == 'maestro' &&
                                                <>
                                                    <TextField
                                                        id="filtromaestro"
                                                        label={`Filtro (${origenAttrs?.filter_by})`}
                                                        value={selectedConf.data.filtromaestro}
                                                        onChange={(e) => {
                                                            const value = e.target.value
                                                            onSelectedConfChange({ filtromaestro: value })
                                                        }}
                                                        style={{ width: "100%", background: "white" }}
                                                    />
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                    <div style={{ padding: "0em 2em 1em 1em", width: "50%", alignSelf: 'center' }}>
                                        <FormControl fullWidth>
                                            {selectedConf?.data?.tipoSource == 'maestro' &&
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            get_secret_data(null, 'origen', null, {
                                                                idmaestro: selectedConf?.data?.idmaestro,
                                                                filtromaestro: selectedConf?.data?.filtromaestro
                                                            })
                                                                .then(response => {
                                                                    const { detail, content } = response || {}
                                                                    if (detail == 'success') setOrigenAttrs(content)
                                                                })
                                                        }}
                                                    >
                                                        Previsualizar
                                                    </Button>
                                                </>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            {["maestro"].includes(selectedConf.data.tipoSource) && origenAttrs?.resultados &&
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ padding: "0em 2em 1em 2em", width: "100%", overflow: 'auto' }}>
                                        <DataGrid
                                            dataSource={origenAttrs?.resultados || []}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            columnResizingMode="nextColumn"
                                        >
                                            <FilterRow visible={true} />
                                            <HeaderFilter visible={true} />
                                            <Paging defaultPageSize={50} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[50, 100, 150, 200]}
                                                showInfo={true}
                                            />
                                        </DataGrid>
                                    </div>
                                </div>
                            }
                            {["db", "bd"].includes(selectedConf?.data?.tipoSource) && selectedConf?.data?.secret_arn && origenAttrs?.tablas && // Si la fuente seleccionada es tipo BASE DE DATOS
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ padding: "0em 2em 1em 2em", width: "100%" }}>
                                        <TabContext value={selectedConf.data.tab}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList onChange={(e, value) => {
                                                    onSelectedConfChange({ tab: value, query: "" })
                                                    setOrigenAttrs({ ...origenAttrs, query_test: null, campos: null, resultados: null })
                                                }}
                                                    aria-label="Tipo request">
                                                    <Tab label="Simple" value="s" />
                                                    <Tab label="Personalizado" value="p" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="s">
                                                <Grid container spacing={0}>
                                                    <Grid item xs={6} style={{ background: 'white' }}>
                                                        <h3 style={{ padding: "0px", margin: "2px 0px 0px 0px", textAlign: 'center' }}>Tablas</h3>
                                                        <List
                                                            width={400}
                                                            rowCount={origenAttrs.tablas ? origenAttrs.tablas.length : 0}
                                                            style={{ width: '100%', maxHeight: "15em", overflow: 'auto' }}
                                                            height={300}
                                                            rowHeight={40}
                                                            rowRenderer={({
                                                                key, index, isScrolling,
                                                                isVisible, style
                                                            }) => {
                                                                const value = isVisible ? origenAttrs.tablas[index] : '...'
                                                                return (
                                                                    <Button variant="text" key={key} onClick={() => {
                                                                        setOrigenAttrs({ ...origenAttrs, query_test: null, campos: null, resultados: null })
                                                                        onSelectedConfChange({ query: `SELECT * FROM ${value}` })
                                                                    }} style={{ ...style }}>
                                                                        {value}
                                                                    </Button>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} style={{ background: 'white' }}>
                                                        <h3 style={{ padding: "0px", margin: "2px 0px 0px 0px", textAlign: 'center' }}>Vistas</h3>
                                                        <List
                                                            width={400}
                                                            rowCount={origenAttrs.vistas ? origenAttrs.vistas.length : 0}
                                                            style={{ width: '100%', maxHeight: "15em", overflow: 'auto' }}
                                                            height={300}
                                                            rowHeight={40}
                                                            rowRenderer={({
                                                                key, index, isScrolling,
                                                                isVisible, style
                                                            }) => {
                                                                const value = isVisible ? origenAttrs.vistas[index] : '...'
                                                                return (
                                                                    <Button variant="text" key={key} onClick={() => {
                                                                        setOrigenAttrs({ ...origenAttrs, query_test: null, campos: null, resultados: null })
                                                                        onSelectedConfChange({ query: `SELECT * FROM ${value}` })
                                                                    }} style={{ ...style }}>
                                                                        {value}
                                                                    </Button>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "1em 0px 0px 0px" }}>
                                                    <TextField
                                                        required
                                                        id="squery"
                                                        label="Query"
                                                        multiline
                                                        maxRows={4}
                                                        value={selectedConf.data.query}
                                                        style={{ width: "100%", background: "white" }}
                                                        disabled
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton aria-label="test" onClick={() => {
                                                                        if (selectedConf.data.query && selectedConf.data.query != "") {
                                                                            testQuery(selectedConf.data.secret_arn, selectedConf.data.query)
                                                                        }
                                                                    }}>
                                                                        <SendIcon />
                                                                    </IconButton>
                                                                    {origenAttrs.campos && origenAttrs.resultados &&
                                                                        <IconButton aria-label="view" onClick={() => setOrigenAttrs({ ...origenAttrs, dialog: true })}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        helperText={!origenAttrs.query_test ? 'Se debe probar el query' : origenAttrs.query_test}
                                                    />
                                                </div>
                                            </TabPanel>
                                            <TabPanel value="p">
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "1em 0px 0px 0px" }}>
                                                    <TextField
                                                        required
                                                        id="pquery"
                                                        label="Query"
                                                        multiline
                                                        maxRows={4}
                                                        value={selectedConf.data.query}
                                                        onChange={(e) => onSelectedConfChange({ query: e.target.value })}
                                                        style={{ width: "100%", background: "white" }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton aria-label="test" onClick={() => {
                                                                        if (selectedConf.data.query && selectedConf.data.query != "") {
                                                                            testQuery(selectedConf.data.secret_arn, selectedConf.data.query)
                                                                        }
                                                                    }}>
                                                                        <SendIcon />
                                                                    </IconButton>
                                                                    {origenAttrs.campos && origenAttrs.resultados &&
                                                                        <IconButton aria-label="view" onClick={() => setOrigenAttrs({ ...origenAttrs, dialog: true })}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        helperText={!origenAttrs.query_test ? 'Se debe probar el query' : origenAttrs.query_test}
                                                    />
                                                </div>
                                            </TabPanel>
                                        </TabContext>
                                    </div>
                                </div>
                            }
                            {["api"].includes(selectedConf?.data?.tipoSource) && selectedConf?.data?.secret_arn &&
                                <OrigenApi
                                    selectedConf={selectedConf}
                                    onSelectedConfChange={onSelectedConfChange}
                                    origenAttrs={origenAttrs}
                                />
                            }
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "0em 0em 5px 2em", width: "100%" }}>
                                    <Button variant="text" onClick={() => {
                                        setSelectedConf(null)
                                        setOrigenAttrs(null)
                                    }}>Cancelar</Button>
                                    <Button variant="contained" onClick={() => save_conector(CONECTOR.ORIGEN)} >Guardar</Button>
                                </div>
                            </div>
                        </Grid>
                    }
                    {/* Configuraciones de destino */}
                    {selectedConf?.data?.accion == CONECTOR.DESTINO &&
                        <Grid item xs={4} md={4} style={{ background: "#D9D9D9", padding: "0em 0em 1em 0em", height: 'calc(100vh - 150px)', overflow: 'auto' }}>
                            {selectedConf?.data?.sources?.length ?
                                <React.Fragment>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ padding: "2em 2em 2em 2em", width: "100%" }}>
                                            <TextField
                                                required
                                                id="destinoName"
                                                label="Nombre"
                                                value={selectedConf.data.nombre}
                                                onChange={(e) => onSelectedConfChange({ nombre: e.target.value })}
                                                style={{ width: "100%", background: "white" }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ padding: "0em 2em 1em 2em", width: "100%" }}>
                                            <TextField
                                                required
                                                id="destinoDescripcion"
                                                label="Descripción"
                                                multiline
                                                maxRows={4}
                                                value={selectedConf.data.descripcion}
                                                onChange={(e) => onSelectedConfChange({ descripcion: e.target.value })}
                                                style={{ width: "100%", background: "white" }}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <div style={{ padding: "0em 1em 0.5em 2em", width: "50%" }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="tipo-destino-label">Tipo de destino</InputLabel>
                                                <Select
                                                    labelId="tipo-destino-label"
                                                    id="tipo-destino"
                                                    value={selectedConf.data.tipoSource}
                                                    label="Tipo de destino"
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        onSelectedConfChange({ tipoSource: value, secret_arn: null, tipo: tipos[value] })
                                                        setDestinos(destinosAll[value])
                                                    }}
                                                    style={{ width: "100%", background: "white" }}
                                                >
                                                    {tipoDestinos.map(tipo => {
                                                        return (
                                                            <MenuItem value={tipo.value}>{tipo.label}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div style={{ padding: "0em 2em 0.5em 1em", width: "50%" }}>
                                            {!["response"].includes(selectedConf.data.tipoSource) ?
                                                <FormControl fullWidth>
                                                    <InputLabel id="destino-label">Destino</InputLabel>
                                                    <Select
                                                        labelId="destino-label"
                                                        id="destino"
                                                        value={selectedConf.data.tipoSource == "s3" ? selectedConf.data.extension : selectedConf.data.secret_arn}
                                                        label="Destino"
                                                        onChange={(e) => {
                                                            const value = e.target.value // ARN o extension
                                                            if (selectedConf.data.tipoSource != "s3") {
                                                                setLoading(true)
                                                                setDestinoAttrs(null)
                                                                // Se obtienen la data de ese secret
                                                                api.Get(`destino/get_destino_data`, {
                                                                    secret_arn: value,
                                                                    conector: selectedConf.id,
                                                                    reload_cache: !flujo?.use_cache,
                                                                })
                                                                    .then(response => {
                                                                        const { data, status } = response || {}
                                                                        const { detail, content } = data || {}
                                                                        setDestinoAttrs(content)
                                                                        onSelectedConfChange({ secret_arn: value })
                                                                    })
                                                                    .catch(e => {
                                                                        const { response } = e || {}
                                                                        const { data, status } = response || {}
                                                                        const { detail, content } = data || {}
                                                                        Alert(`${detail}: ${content}`, { variant: 'error' })
                                                                    })
                                                                    .finally(() => {
                                                                        setLoading(false)
                                                                    })
                                                            } else {
                                                                onSelectedConfChange({ extension: value })
                                                            }
                                                        }}
                                                        style={{ width: "100%", background: "white" }}
                                                    >
                                                        {destinos.map(destino => {
                                                            return (
                                                                <MenuItem value={destino.value}>{destino.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                :
                                                ["response"].includes(selectedConf.data.tipoSource) ?
                                                    <TextField
                                                        required
                                                        id="destinoResponseIdentifier"
                                                        label="Response ID"
                                                        value={selectedConf.data.responseIdentifier}
                                                        onChange={(e) => onSelectedConfChange({ responseIdentifier: e.target.value })}
                                                        style={{ width: "100%", background: "white" }}
                                                    />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    {["s3"].includes(selectedConf.data.tipoSource) &&
                                        <>
                                            <div style={{ width: "100%", display: "flex", padding: "0.5em 2em 1em 2em", justifyContent: "center" }}>
                                                <div style={{ padding: "0em 1em 0.5em 0em", width: "50%" }}>
                                                    <TextField
                                                        id="destinoS3decode"
                                                        label="S3 decode (utf-8)"
                                                        value={selectedConf.data.s3_decode}
                                                        onChange={(e) => onSelectedConfChange({ s3_decode: e.target.value })}
                                                        style={{ width: "100%", background: "white" }}
                                                    />
                                                </div>
                                                <div style={{ padding: "0em 0em 0.5em 1em", width: "50%" }}>
                                                    <TextField
                                                        id="destinoS3path"
                                                        label="S3 path"
                                                        value={selectedConf.data.s3_path}
                                                        onChange={(e) => onSelectedConfChange({ s3_path: e.target.value })}
                                                        style={{ width: "100%", background: "white" }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ width: "100%", display: "flex", padding: "0.5em 2em 1em 2em", justifyContent: "center" }}>
                                                <div style={{ padding: "0em 1em 0.5em 0em", width: "50%" }}>
                                                    <TextField
                                                        id="destinoS3filename"
                                                        label="S3 filename"
                                                        value={selectedConf.data.s3_filename}
                                                        onChange={(e) => onSelectedConfChange({ s3_filename: e.target.value })}
                                                        style={{ width: "100%", background: "white" }}
                                                    />
                                                </div>
                                                <div style={{ padding: "0em 0em 0.5em 1em", width: "50%" }}>
                                                    <FormGroup fullWidth>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedConf.data.s3_filename_keep_date}
                                                                    onChange={(e) => onSelectedConfChange({ s3_filename_keep_date: e.target.checked })}
                                                                />
                                                            }
                                                            label="Concatenar fecha al nombre del archivo"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {["s3"].includes(selectedConf.data.tipoSource) &&
                                        <div style={{ width: "100%", display: "flex", padding: "0.5em 2em 1em 2em", justifyContent: "center" }}>
                                            <Button
                                                style={{ width: "100%" }}
                                                variant="outlined"
                                                onClick={() => {
                                                    setLoading(true)
                                                    const id = selectedConf.id?.replace('conector:', '')
                                                    api.Get(`conector/${id}/list_s3_files`)
                                                        .then((response) => {
                                                            const { data, status } = response || {}
                                                            const { detail, content } = data || {}
                                                            setS3DestinoDialog({
                                                                open: true,
                                                                files: content
                                                            })
                                                        })
                                                        .catch((e) => {
                                                            const { response } = e || {}
                                                            const { data, status } = response || {}
                                                            const { detail, content } = data || {}
                                                            Alert(`${detail}: ${content}`, { variant: 'error' })
                                                        })
                                                        .finally(() => {
                                                            setLoading(false)
                                                        })
                                                }}
                                            >
                                                Inspeccionar archivos generados
                                            </Button>
                                        </div>
                                    }
                                    <DndProvider backend={HTML5Backend}>
                                        {/* {destinoAttrs && destinoAttrs['origen-campos'] &&
                                            <Grid container spacing={0} style={{ width: '100%', padding: "0em 2em 1em 2em" }}>
                                                <AutoSizer disableHeight>
                                                    {({ width }) => (
                                                        <GridVirtualized
                                                            cellRenderer={({ columnIndex, rowIndex, style }) => {
                                                                const index = rowIndex * 2 + columnIndex;
                                                                const value = destinoAttrs['origen-campos'][index]
                                                                return (
                                                                    value ? (
                                                                        <div
                                                                            style={{
                                                                                ...style,
                                                                                display: 'flex',
                                                                                width: (width - 18) / 2,
                                                                                justifyContent: 'center',
                                                                                alignSelf: 'center'
                                                                            }}
                                                                        >
                                                                            <DraggableBox
                                                                                // style={style}
                                                                                index={rowIndex}
                                                                                label={value}
                                                                                value={value}
                                                                                conf={selectedConf.data}
                                                                                onDrop={(dropResult, monitor) => {
                                                                                    created_body_changes(dropResult?.name, value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : null
                                                                )
                                                            }}
                                                            columnWidth={(width - 18) / 2}
                                                            columnCount={2} // Ajusta según la necesidad, usando 1 para simplificar
                                                            height={destinoAttrs['origen-campos']?.length <= 2 ? 60 :
                                                                destinoAttrs['origen-campos']?.length <= 4 ? 120 :
                                                                    destinoAttrs['origen-campos']?.length <= 6 ? 180 :
                                                                        destinoAttrs['origen-campos']?.length <= 8 ? 240 :
                                                                            300
                                                            }
                                                            rowHeight={60}
                                                            rowCount={destinoAttrs['origen-campos'] ? Math.ceil(destinoAttrs['origen-campos'].length / 2) : 0}
                                                            width={width}
                                                        />
                                                    )}
                                                </AutoSizer>
                                            </Grid>
                                        } */}
                                        {/* Se renderizan los campos para la configuracion */}
                                        {destinoAttrs && destinoAttrs.app &&
                                            <AppConfigGenerator app={destinoAttrs.app || {}} setState={onSelectedConfChange} state={selectedConf.data.temp_body || {}} origen_campos={destinoAttrs['origen-campos']} />
                                        }
                                    </DndProvider>
                                </React.Fragment>
                                :
                                <h2 style={{ padding: '0em 1em 0em 1em' }}>
                                    Es necesario asociar una fuente de datos al destino.
                                </h2>
                            }
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "0em 0em 5px 2em", width: "100%" }}>
                                    <Button variant="text" onClick={() => {
                                        setSelectedConf(null)
                                        setDestinoAttrs(null)
                                    }}>Cancelar</Button>
                                    <Button variant="contained" onClick={() => save_conector(CONECTOR.DESTINO)} disabled={!selectedConf?.data?.sources?.length}>
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    }
                    {/* Configuraciones de accion */}
                    {selectedConf?.data?.accion == CONECTOR.ACCION &&
                        <Grid item xs={4} md={4} style={{ background: "#D9D9D9", padding: "0em 0em 1em 0em", height: 'calc(100vh - 150px)', overflow: 'auto' }}>
                            {selectedConf?.data?.configuracion_entrada?.sources?.length ?
                                <React.Fragment>
                                    {/* CAMPOS GENERALES DE ACCION */}
                                    <>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ padding: "2em 2em 2em 2em", width: "100%" }}>
                                                <TextField
                                                    required
                                                    id="accionName"
                                                    label="Nombre"
                                                    disabled
                                                    value={selectedConf.data.nombre}
                                                    onChange={(e) => { }}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ padding: "0em 2em 1em 2em", width: "100%" }}>
                                                <TextField
                                                    required
                                                    id="accionDescripcion"
                                                    label="Descripción"
                                                    multiline
                                                    maxRows={4}
                                                    value={selectedConf.data.descripcion}
                                                    onChange={(e) => onSelectedConfChange({ descripcion: e.target.value })}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                            <div style={{ padding: "0em 1em 1em 2em", width: "50%" }}>
                                                <FormGroup fullWidth>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedConf?.data?.configuracion_salida?.include_origin_fields}
                                                                onChange={(e) => onSelectedConfChange({
                                                                    configuracion_salida: {
                                                                        ...selectedConf?.data?.configuracion_salida || {},
                                                                        include_origin_fields: e.target.checked
                                                                    }
                                                                })}
                                                            />
                                                        }
                                                        label="¿Incluir campos del origen en la salida?"
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </>
                                    <hr />
                                    {/* <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                        <div style={{ display: 'flex', padding: "0em 2em 0em 2em", width: "100%", justifyContent: "flex-end" }}>
                                            <Tooltip title="Agrandar" placement="left">
                                                <IconButton
                                                    aria-label="Fullscreen"
                                                    onClick={() => setFullscreen({
                                                        ...Fullscreen,
                                                        open: true,
                                                    })}
                                                >
                                                    <FullScreenIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div> */}
                                    <DndProvider backend={HTML5Backend}>
                                        {/* Configuraciones de entrada */}
                                        {/* Configuraciones para concatenar y renombrar */}
                                        {[TIPO_ACCION.CONCAT, TIPO_ACCION.RENAME].includes(selectedConf?.data?.tipo_accion) &&
                                            <>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <h4 style={{ margin: '1em 0px 0.5em 0px', alignSelf: 'center' }}>
                                                        Campos de salida
                                                    </h4>
                                                    <Tooltip
                                                        style={{ alignSelf: 'center' }}
                                                        title={actionTooltipOut()}
                                                    >
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                {selectedConf?.data?.configuracion_salida?.campos?.map((campo, index) => {
                                                    const action = selectedConf?.data?.tipo_accion
                                                    const Componente = action == TIPO_ACCION.CONCAT ? Concat :
                                                        action == TIPO_ACCION.RENAME ? Rename :
                                                            () => { }
                                                    return (
                                                        <Componente
                                                            key={index}
                                                            index={index}
                                                            campo={campo}
                                                            onSelectedConfChange={onSelectedConfChange}
                                                            campos_origen={accionAttrs ? accionAttrs['origen-campos'] || [] : []}
                                                            configuracion_salida={selectedConf?.data?.configuracion_salida || {}}
                                                        />
                                                    )
                                                })}
                                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ margin: "0em 2em 1em 1em" }}
                                                        variant="outlined"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            const customProps = {}
                                                            const action = selectedConf?.data?.tipo_accion
                                                            if (action == TIPO_ACCION.CONCAT) {
                                                                customProps.separador = ""
                                                            }
                                                            if (action == TIPO_ACCION.RENAME) {
                                                                customProps.keep_field = false
                                                            }
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...selectedConf?.data?.configuracion_salida,
                                                                    campos: [
                                                                        ...(selectedConf?.data?.configuracion_salida?.campos || []),
                                                                        { nombre: "", campos: [], ...customProps }
                                                                    ]
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        Agregar
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {/* Configuraciones para python script */}
                                        {[TIPO_ACCION.SCRIPT].includes(selectedConf?.data?.tipo_accion) &&
                                            <>
                                                <Script
                                                    onSelectedConfChange={onSelectedConfChange}
                                                    librerias={accionAttrs ? accionAttrs['librerias'] || [] : []}
                                                    campos_origen={accionAttrs ? accionAttrs['origen-campos'] || [] : []}
                                                    configuracion_salida={selectedConf?.data?.configuracion_salida || {}}
                                                    configuracion_entrada={selectedConf?.data?.configuracion_entrada || {}}
                                                    lang={selectedConf?.data?.configuracion_salida?.lang || 'python'}
                                                    selectedConf={selectedConf}
                                                    setLoading={setLoading}
                                                    conectores={conectores}
                                                    onCancel={() => {
                                                        setSelectedConf(null)
                                                        setAccionAttrs(null)
                                                    }}
                                                    onSave={() => save_conector(CONECTOR.ACCION)}
                                                />
                                            </>
                                        }
                                        {/* Configuraciones para router */}
                                        {[TIPO_ACCION.ROUTER].includes(selectedConf?.data?.tipo_accion) &&
                                            <>
                                                {selectedConf?.data?.configuracion_salida?.routes?.map((route, index) => {
                                                    const action = selectedConf?.data?.tipo_accion
                                                    const Componente = action == TIPO_ACCION.ROUTER ? Router :
                                                        () => { }
                                                    return (
                                                        <Componente
                                                            id={selectedConf.id}
                                                            key={index}
                                                            index={index}
                                                            route={route}
                                                            edges={edges}
                                                            setEdges={setEdges}
                                                            onSelectedConfChange={onSelectedConfChange}
                                                            targets={accionAttrs ? accionAttrs['targets'] || [] : []}
                                                            campos_origen={accionAttrs ? accionAttrs['origen-campos'] || [] : []}
                                                            configuracion_salida={selectedConf?.data?.configuracion_salida || {}}
                                                        />
                                                    )
                                                })}
                                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ margin: "0em 2em 1em 1em" }}
                                                        variant="outlined"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            const customProps = {}
                                                            const action = selectedConf?.data?.tipo_accion
                                                            if (action == TIPO_ACCION.ROUTER) {
                                                                // customProps.TEST = ""
                                                            }
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...selectedConf?.data?.configuracion_salida,
                                                                    routes: [
                                                                        ...(selectedConf?.data?.configuracion_salida?.routes || []),
                                                                        {
                                                                            conditions: [],
                                                                            target: null,
                                                                            nombre: null,
                                                                            operador: 1,
                                                                            ...customProps
                                                                        }
                                                                    ]
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        Agregar
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                        {/* Configuraciones para join */}
                                        {[TIPO_ACCION.JOIN].includes(selectedConf?.data?.tipo_accion) &&
                                            <>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    {/* FUENTE PRINCIPAL */}
                                                    <div style={{ display: 'flex', width: "100%", padding: "1em 2em 1em 2em" }}>
                                                        <Autocomplete
                                                            id={`join-data0`}
                                                            options={accionAttrs ? accionAttrs['sources'] || [] : []}
                                                            value={(accionAttrs ? accionAttrs['sources'] || [] : []).find((source) => source.id === selectedConf?.data?.configuracion_salida?.data0) || null}
                                                            getOptionLabel={(option) => option?.nombre + ' - ' + option?.descripcion || ''}
                                                            getOptionKey={(option) => option.id || ''}
                                                            onChange={(event, newValue) => {
                                                                onSelectedConfChange({
                                                                    configuracion_salida: {
                                                                        ...selectedConf?.data?.configuracion_salida || {},
                                                                        data0: newValue?.id,
                                                                    }
                                                                })
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label="Fuente principal" />}
                                                            style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                        />
                                                    </div>
                                                </div>
                                                {selectedConf?.data?.configuracion_salida?.campos?.map((campo, index) => {
                                                    const action = selectedConf?.data?.tipo_accion
                                                    const Componente = action == TIPO_ACCION.JOIN ? Join :
                                                        () => { }
                                                    return (
                                                        <Componente
                                                            id={selectedConf.id}
                                                            key={index}
                                                            index={index}
                                                            campo={campo}
                                                            conf={selectedConf?.data?.configuracion_salida}
                                                            onSelectedConfChange={onSelectedConfChange}
                                                            app={accionAttrs ? accionAttrs['app'] || {} : {}}
                                                            sources={accionAttrs ? accionAttrs['sources'] || [] : []}
                                                            campos_origen={accionAttrs ? accionAttrs['origen-campos'] || [] : []}
                                                            configuracion_salida={selectedConf?.data?.configuracion_salida || {}}
                                                        />
                                                    )
                                                })}
                                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                        style={{ margin: "0em 2em 1em 1em" }}
                                                        variant="outlined"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            const customProps = {}
                                                            const action = selectedConf?.data?.tipo_accion
                                                            if (action == TIPO_ACCION.JOIN) {
                                                                // customProps.TEST = ""
                                                            }
                                                            onSelectedConfChange({
                                                                configuracion_salida: {
                                                                    ...selectedConf?.data?.configuracion_salida,
                                                                    campos: [
                                                                        ...(selectedConf?.data?.configuracion_salida?.campos || []),
                                                                        {
                                                                            data1: null,
                                                                            conditions: [/*{ field0: 'nombre campo data0', conditional: 'condicional', field1: 'nombre campo data1' } */],
                                                                            campos: [/*{ nombre: 'nombre final', valor: 'nombre campo data1' } */],
                                                                            operador: 'inner',
                                                                            ...customProps
                                                                        }
                                                                    ]
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        Agregar
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </DndProvider>
                                </React.Fragment>
                                :
                                <h2 style={{ padding: '0em 1em 0em 1em' }}>
                                    Es necesario asociar una fuente de datos al destino.
                                </h2>
                            }
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <div style={{ padding: "1em 0em 5px 2em", width: "100%" }}>
                                    <Button variant="text" onClick={() => {
                                        setSelectedConf(null)
                                        setAccionAttrs(null)
                                    }}>Cancelar</Button>
                                    <Button variant="contained" onClick={() => save_conector(CONECTOR.ACCION)} disabled={!selectedConf?.data?.configuracion_entrada?.sources?.length}>
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>

            {/* Dialogo de visualización de datos de origen */}
            <Dialog onClose={() => setOrigenAttrs({ ...origenAttrs, dialog: false })} open={origenAttrs?.dialog} fullWidth maxWidth={'xl'}>
                <DialogTitle>Resultados del query</DialogTitle>
                <DialogContent>
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            return (
                                <TableVirtulized
                                    width={(width / 5) * (origenAttrs?.campos?.length || 0)}
                                    height={450}
                                    headerHeight={30}
                                    rowHeight={30}
                                    rowCount={origenAttrs.resultados ? origenAttrs.resultados.length : 0}
                                    rowGetter={({ index }) => origenAttrs?.resultados[index]}
                                    overscanRowCount={5}
                                >
                                    {origenAttrs?.campos?.map((column) => (
                                        <ColumnVirtualized
                                            key={column}
                                            label={column}
                                            dataKey={column}
                                            width={width / 5}
                                            headerRenderer={({ label }) =>
                                                <div style={{ fontWeight: 'bold' }}>
                                                    {label}
                                                </div>
                                            }
                                        />
                                    ))}
                                </TableVirtulized>
                            )
                        }}
                    </AutoSizer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOrigenAttrs({ ...origenAttrs, dialog: false })}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            {/* Dialogo de creación para nuevo CONECTOR */}
            <Dialog onClose={() => setNewConnector({ open: false })} open={newConnector.open}>
                <DialogTitle>Nuevo conector {newConnector.type}</DialogTitle>
                <form style={{ padding: "0px 25px 25px 25px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="nombre-conector"
                                label="Nombre"
                                variant="outlined"
                                value={newConnector?.config?.data?.nombre}
                                onChange={(event) => {
                                    if (!newConnector.disabled) {
                                        setNewConnector({ ...newConnector, config: { ...newConnector.config, data: { ...newConnector.config.data, nombre: event.target.value } } })
                                    }
                                }}
                                style={{ width: "100%" }}
                                disabled={newConnector.disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="descripcion-conector"
                                label="Descripción"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={newConnector?.config?.data?.descripcion}
                                onChange={(event) => setNewConnector({ ...newConnector, config: { ...newConnector.config, data: { ...newConnector.config.data, descripcion: event.target.value } } })}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button variant="text" color='primary' onClick={() => setNewConnector({ open: false })}>Cancelar</Button>
                            <Button variant="contained" color='primary' onClick={save_new_conector}>Crear</Button>
                        </Grid>
                    </Grid>
                </form>
            </Dialog>

            {/* Dialogo CRUD de tareas del flujo */}
            <Dialog onClose={() => setTasksOpen(false)} open={tasksOpen}>
                <DialogTitle>
                    Eventos&nbsp;
                    <small style={{ fontSize: "0.6em" }}>
                        (Zona horaria UTC)
                    </small>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setTasks([
                            ...tasks.map(task => ({ ...task, expanded: false })),
                            {
                                nombre: "",
                                cron: "",
                                pausado: false,
                                expanded: true,
                                variables: flujo.variables
                            }
                        ])
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <AddIcon />
                </IconButton>
                <DialogContent style={{ padding: "0px 20px 20px 20px", width: "500px" }}>
                    {tasks.map((task, index) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <div style={{ width: "100%" }}>
                                    <Accordion
                                        expanded={task.expanded}
                                        onChange={(event, expanded) => {
                                            setTasks(tasks.map((t, i) => {
                                                t.expanded = (i == index ? expanded : false)
                                                return t
                                            }))
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id={`tarea-${index}`}
                                        >
                                            <EditableText
                                                focused={true}
                                                spanStyle={{ cursor: 'text' }}
                                                containerStyle={{ margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                                inline={false}
                                                initialText={task.nombre}
                                                placeholder="Nombre de la tarea"
                                                onChange={(event) => {
                                                    const newTasks = [...tasks]
                                                    newTasks[index].nombre = event.target.value
                                                    setTasks(newTasks)
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    setSettingsOpen(true)
                                                    setSettings({
                                                        flujo: task,
                                                        showGeneral: false,
                                                        setFlujo: (newTask) => {
                                                            const newTasks = [...tasks]
                                                            newTasks[index] = newTask
                                                            setTasks(newTasks)
                                                        },
                                                        handleSave: handleCloseSettings,
                                                    })
                                                }}
                                            >
                                                <SettingsIcon
                                                    style={{ marginLeft: "auto" }}
                                                />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Cron
                                                onChange={(value) => {
                                                    const newTasks = [...tasks]
                                                    newTasks[index].cron = value
                                                    setTasks(newTasks)
                                                }}
                                                locale="es"
                                                value={task.cron}
                                                showResultText={true}
                                                showResultCron={false}
                                                options={{
                                                    headers: [
                                                        HEADER.MONTHLY, HEADER.WEEKLY,
                                                        HEADER.MINUTES, HEADER.HOURLY,
                                                        HEADER.DAILY, // HEADER.CUSTOM
                                                    ]
                                                }}
                                                translateFn={(value) => {
                                                    return cronTranslation[value]
                                                }}
                                            />
                                        </AccordionDetails>
                                        <AccordionActions>
                                            <Button
                                                color="error"
                                                variant="contained"
                                                startIcon={
                                                    <DeleteIcon />
                                                }
                                                onClick={() => {
                                                    confirmAlert({
                                                        title: 'Confirmación',
                                                        message: '¿Está seguro de eliminar el evento?',
                                                        overlayClassName: "confirm-alert",
                                                        buttons: [
                                                            {
                                                                label: 'Sí',
                                                                onClick: () => {
                                                                    const newTasks = [...tasks]
                                                                    newTasks.splice(index, 1)
                                                                    setTasks(newTasks)
                                                                }
                                                            },
                                                            {
                                                                label: 'No',
                                                                onClick: () => { }
                                                            }
                                                        ]
                                                    });
                                                }}
                                            >
                                                Eliminar
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={
                                                    task.pausado ? <PlayIcon /> : <PauseIcon />
                                                }
                                                onClick={() => {
                                                    const newTasks = [...tasks]
                                                    newTasks[index].pausado = !task.pausado
                                                    setTasks(newTasks)
                                                }}
                                            >
                                                {task.pausado ? "Reanudar" : "Pausar"}
                                            </Button>
                                        </AccordionActions>
                                    </Accordion>
                                </div>
                            </div>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setTasksOpen(false)}>
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            saveFlowTasks()
                        }}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* S3 destino files dialog */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={S3destinoDialog.open}
                onClose={() => setS3DestinoDialog({ open: false, files: [] })}
            >
                <DialogTitle>
                    {"Archivos generados"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setS3DestinoDialog({ open: false, files: [] })}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <S3Files files={S3destinoDialog.files} setLoading={setLoading} />
                </DialogContent>
            </Dialog>

            {/* MCU S3 files dialog */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={openS3Dialog}
                onClose={() => setOpenS3Dialog(false)}
            >
                <DialogTitle>
                    {"Archivos MCU"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenS3Dialog(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <HojaS3 />
                </DialogContent>
            </Dialog>

            {/* FullScreen dialog */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={Fullscreen.open}
                onClose={() => closeFullScreen()}
            >
                <DialogTitle>
                    {Fullscreen.title || "Configuración"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => closeFullScreen()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Fullscreen.Component />
                </DialogContent>
            </Dialog>

            {/* LOGS dialog */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={executionLog?.length > 0}
                onClose={() => setExecutionLog([])}
            >
                <DialogTitle>
                    Logs de ejecución
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setExecutionLog([])}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                    <DataGrid
                        dataSource={executionLog || []}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        columnResizingMode="nextColumn"
                    >
                        <Column
                            width={'auto'}
                            dataField="START_DATE"
                            caption="Inicio"
                            cellRender={({ data }) => {
                                // la fecha viene en utc y se debe convertir a local con moment
                                return (
                                    <span>
                                        {moment.utc(data.START_DATE).local().format('YYYY-MM-DD HH:mm:ss.SSS')}
                                    </span>
                                )
                            }}
                        />
                        <Column
                            width={'auto'}
                            dataField="MESSAGE"
                            caption="Mensaje"
                            cellRender={({ data }) => {
                                return (
                                    <span style={{ whiteSpace: 'normal' }}>
                                        <React.Fragment>
                                            {data.MESSAGE}
                                        </React.Fragment>
                                    </span>
                                )
                            }}
                        />
                        <Column
                            width={'auto'}
                            dataField="DURATION"
                            caption="Duración"
                            cellRender={({ data }) => {
                                return (
                                    <span>
                                        {(data.DURATION / 1000).toFixed(6)} s
                                    </span>
                                )
                            }}
                        />
                        <Column
                            width={'auto'}
                            dataField="STATUS"
                            caption="Estado"
                            cellRender={({ data }) => {
                                const colors = { 'SUCCESS': 'green', 'ERROR': 'red', 'WARNING': 'orange' }
                                return (
                                    <span style={{ color: colors[data.STATUS] }}>
                                        {data.STATUS}
                                    </span>
                                )
                            }}
                        />
                        <FilterRow visible={false} />
                        <HeaderFilter visible={false} />
                        <Paging defaultPageSize={50} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[50, 100, 150, 200]}
                            showInfo={true}
                        />
                        <Summary>
                            <TotalItem
                                column="DURATION"
                                summaryType="sum"
                                valueFormat="number"
                                customizeText={(data) => `${(data?.value / 1000).toFixed(6)} s`}
                            />
                        </Summary>
                    </DataGrid>
                </DialogContent>
            </Dialog>

            {/* Ventana de configuraciones generales del flujo */}
            <Settings
                open={settingsOpen}
                handleClose={handleCloseSettings}
                setLoading={setLoading}
                flujo={flujo}
                setFlujo={setFlujo}
                tags={tags}
                {...settings}
            />

            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Boton de accion flotante */}
            {/* <SpeedDial
                ariaLabel="Acciones"
                sx={{ position: 'absolute', bottom: 40, right: 40 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial> */}
        </React.Fragment >
    );
};

export default CrearFlujo;
