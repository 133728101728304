import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { useSnackbar } from 'notistack';
import ListAltIcon from '@mui/icons-material/ListAltTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/ContentCopy';
import InventoryIcon from '@mui/icons-material/Inventory2';
import PlayIcon from '@mui/icons-material/PlayArrow';
import FullScreenIcon from '@mui/icons-material/FullscreenTwoTone';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FixedSizeList } from 'react-window';
import api from '../../../../utils/api';
import { LogViewer } from '@patternfly/react-log-viewer';
import { AutoSizer, Grid as GridVirtualized, List, Column as ColumnVirtualized, Table as TableVirtulized } from 'react-virtualized';
import Button from '@mui/material/Button';

import DataGrid, {
    Scrolling, Sorting, Column
} from 'devextreme-react/data-grid';

const MainComponent = ({
    campos_origen,
    onSelectedConfChange,
    configuracion_salida,
    lang = 'python',
    fullScreen,
    setFullScreen,
    languages,
    Alert,
    librerias = [],
    conectores = [],
    configuracion_entrada,
    selectedConf = {},
    setLoading,
}) => {

    const [copyDialog, setCopyDialog] = React.useState({
        open: false,
        options: [],
        search: '',
        filteredOptions: [],
        prefix: '',
        sufix: '',
        title: '',
    })

    const [sources, setSources] = React.useState([])
    const [execTest, setExecTest] = React.useState({})

    const tooltip = {
        placement: 'top',
    }

    function closeCopyDialog() {
        setCopyDialog({
            open: false,
            options: [],
            prefix: '',
            sufix: '',
            title: '',
        })
    }

    function renderItemRow(props) {
        const { index, style } = props;

        return (
            <ListItem
                key={index}
                style={style}
                disablePadding
            >
                <ListItemButton>
                    <ListItemText primary={`${copyDialog?.filteredOptions?.[index]}`} />
                    <ListItemIcon>
                        <IconButton
                            edge="end"
                            aria-label="copy"
                            onClick={() => {
                                navigator.clipboard.writeText(`${copyDialog?.prefix || ''}${copyDialog?.filteredOptions[index]}${copyDialog?.sufix || ''}`);
                                Alert(`Item copiado.`, { variant: 'success', autoHideDuration: 1500 })
                                closeCopyDialog()
                            }}
                        >
                            <CopyIcon />
                        </IconButton>
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
        );
    }

    React.useEffect(() => {
        setCopyDialog({
            ...copyDialog,
            filteredOptions: copyDialog.options,
            search: ''
        })
    }, [copyDialog.options])

    React.useEffect(() => {
        if (copyDialog?.search && copyDialog?.search?.length > 0) {
            setCopyDialog({
                ...copyDialog,
                filteredOptions: copyDialog.options.filter((item) => item.toLowerCase().includes(copyDialog.search.toLowerCase()))
            })
        } else {
            setCopyDialog({
                ...copyDialog,
                filteredOptions: copyDialog.options
            })
        }
    }, [copyDialog.search])

    React.useEffect(() => {
        setSources(
            configuracion_entrada?.sources?.map((source) => {
                const label = conectores.find((conector) => conector.id === source)?.data.nombre
                return {
                    value: source,
                    label
                }
            })
        )
    }, [configuracion_entrada?.sources])

    const maxLoggerHeight = 450

    let loggerHeight = execTest?.logs ? (execTest?.logs.match(/\n/g) || []).length : maxLoggerHeight
    loggerHeight = 29 + (loggerHeight * 24)
    loggerHeight = loggerHeight > maxLoggerHeight ? maxLoggerHeight : loggerHeight

    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                {/* Configuracion de fuentes */}
                <div style={{ display: 'flex', padding: "0em 2em 1em 2em", width: "100%" }}>
                    <Autocomplete
                        id="principal-source"
                        options={sources}
                        value={sources.find((source) => source.value === configuracion_entrada?.data0) || null}
                        getOptionLabel={(option) => option?.label || ''}
                        getOptionKey={(option) => option.value || ''}
                        onChange={(e, newValue) => {
                            onSelectedConfChange({
                                configuracion_entrada: {
                                    ...configuracion_entrada || {},
                                    data0: newValue.value
                                }
                            })
                        }}
                        renderInput={(params) => <TextField {...params} label="Fuente principal (data)" variant="outlined" />}
                        style={{ width: "100%", backgroundColor: "white" }}
                    />
                </div>
                {sources.map((__source__, index) => {
                    if (index > 0) {
                        return (
                            <div style={{ display: 'flex', padding: "0em 2em 1em 2em", width: "100%" }}>
                                <Autocomplete
                                    id="principal-source"
                                    options={sources}
                                    value={sources.find((source) => source.value === (configuracion_entrada || {})[`data${index}`]) || null}
                                    getOptionLabel={(option) => option?.label || ''}
                                    getOptionKey={(option) => option.value || ''}
                                    onChange={(e, newValue) => {
                                        onSelectedConfChange({
                                            configuracion_entrada: {
                                                ...configuracion_entrada || {},
                                                [`data${index}`]: newValue.value
                                            }
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label={`Fuente alterna (data${index})`} variant="outlined" />}
                                    style={{ width: "100%", backgroundColor: "white" }}
                                />
                            </div>
                        )
                    }
                })}
                {/* Controles */}
                <div style={{ display: 'flex', padding: "0em 2em 0em 2em", width: "100%", justifyContent: "flex-end" }}>
                    {/* Campos del origen disponibles */}
                    <Tooltip title="Campos del origen" placement={tooltip.placement}>
                        <IconButton
                            onClick={() => {
                                let prefix = ''
                                let sufix = ''
                                switch (lang) {
                                    case 'python':
                                        prefix = 'data[\''
                                        sufix = '\']'
                                        break;
                                    default:
                                        prefix = ''
                                        sufix = ''
                                        break;
                                }
                                setCopyDialog({
                                    open: true,
                                    options: campos_origen,
                                    title: 'Campos del origen',
                                    prefix,
                                    sufix,
                                })
                            }}
                        >
                            <ListAltIcon />
                        </IconButton>
                    </Tooltip>
                    {/* Librerias disponibles */}
                    <Tooltip title="Librerias disponibles" placement={tooltip.placement}>
                        <IconButton
                            onClick={() => {
                                setCopyDialog({
                                    open: true,
                                    options: librerias,
                                    title: 'Librerias disponibles',
                                    prefix: 'import ',
                                })
                            }}
                        >
                            <InventoryIcon />
                        </IconButton>
                    </Tooltip>
                    {/* Ejecutar código */}
                    <Tooltip title="Ejecutar" placement={tooltip.placement}>
                        <IconButton
                            onClick={() => {
                                setLoading(true)
                                api.Post('conector/test_pyscript', selectedConf)
                                    .then((response) => {
                                        const { data } = response || {}
                                        const { content } = data || {}
                                        const { campos, resultados, logs } = content || {}
                                        setExecTest({
                                            ...content,
                                            open: true,
                                        })
                                        Alert(`Ejecución exitosa.`, { variant: 'success', autoHideDuration: 1500 })
                                    })
                                    .catch((e) => {
                                        const { error } = e?.response?.data || {}
                                        Alert(error || `Error en la ejecución.`, { variant: 'error' })
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                        >
                            <PlayIcon />
                        </IconButton>
                    </Tooltip>
                    {/* Fullscreen */}
                    {!fullScreen &&
                        <Tooltip title="Agrandar" placement={tooltip.placement}>
                            <IconButton
                                aria-label="fullscreen"
                                onClick={() => setFullScreen(true)}
                            >
                                <FullScreenIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                {/* Editor de codigo */}
                <div style={{ display: 'flex', padding: "0em 2em 0em 2em", width: "100%", maxHeight: fullScreen ? "70vh" : "400px", overflow: 'auto' }}>
                    <CodeMirror
                        value={configuracion_salida?.code || ""}
                        height="auto"
                        theme="dark"
                        extensions={[languages[lang]]}
                        onChange={(value, viewUpdate) => {
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    code: value
                                }
                            })
                        }}
                        style={{ width: "100%" }}
                    />
                </div>
            </div>
            {/* EXECUTION PREVIEW DIALOG */}
            <Dialog
                fullWidth
                maxWidth={"xl"}
                open={execTest?.open}
                onClose={() => setExecTest({ open: false })}
            >
                <DialogTitle>
                    Previsualización de ejecución
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setExecTest({ open: false })}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "center" }}>
                        <div style={{ display: 'flex', padding: "2em 2em 2em 2em", width: "100%", overflow: 'auto' }}>
                            <DataGrid
                                dataSource={execTest?.resultados || []}
                                // height={440}
                                showBorders={true}
                            >
                                {execTest?.resultados?.length > 0 && Object.keys(execTest?.resultados?.[0]).map((key) => {
                                    return (
                                        <Column
                                            key={key}
                                            width={'auto'}
                                            dataField={key}
                                            caption={key}
                                            dataType="string"
                                        />
                                    )
                                })}
                                <Sorting mode="none" />
                                <Scrolling mode="virtual" />
                            </DataGrid>
                        </div>
                        {execTest?.logs &&
                            <div style={{ display: 'flex', padding: "2em 2em 2em 2em", width: "100%" }}>
                                <LogViewer
                                    height={`${loggerHeight}px`}
                                    hasLineNumbers={true}
                                    data={execTest?.logs?.replace(/\n([^\n]*)$/, '' + '$1')}
                                    theme="dark"
                                    header={<h3 style={{ margin: 0 }}>Logs</h3>}
                                    style={{ maxHeight: `${maxLoggerHeight}px`, width: "100%" }}
                                />
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            {/* COPY DIALOG */}
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={copyDialog?.open}
                onClose={() => closeCopyDialog()}
            >
                <DialogTitle>
                    {copyDialog?.title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => closeCopyDialog()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div style={{ padding: "0em 0em 1em 0em" }}>
                        <TextField
                            label="Buscar"
                            variant="outlined"
                            value={copyDialog?.search}
                            onChange={(e) => setCopyDialog({ ...copyDialog, search: e.target.value })}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <FixedSizeList
                        height={200}
                        width={'auto'}
                        itemSize={40}
                        itemCount={copyDialog?.filteredOptions?.length}
                        overscanCount={5}
                    >
                        {renderItemRow}
                    </FixedSizeList>
                </DialogContent>
            </Dialog>
        </>
    )
}

const Script = ({
    campos_origen,
    librerias = [],
    onSelectedConfChange,
    configuracion_salida,
    configuracion_entrada,
    lang = 'python',
    conectores = [],
    selectedConf = {},
    setLoading,
    onCancel,
    onSave,
}) => {
    const [fullScreen, setFullScreen] = React.useState(false)

    const { enqueueSnackbar: Alert } = useSnackbar();
    const languages = {
        python: langs.python()
    }

    return (
        <>
            <MainComponent
                campos_origen={campos_origen}
                onSelectedConfChange={onSelectedConfChange}
                configuracion_salida={configuracion_salida}
                lang={lang}
                fullScreen={fullScreen}
                setFullScreen={setFullScreen}
                languages={languages}
                Alert={Alert}
                librerias={librerias}
                configuracion_entrada={configuracion_entrada}
                conectores={conectores}
                selectedConf={selectedConf}
                setLoading={setLoading}
            />
            {/* FULLSCREEN CODE */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={fullScreen}
                onClose={() => setFullScreen(false)}
            >
                <DialogTitle>
                    {lang.toUpperCase()}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setFullScreen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <MainComponent
                        campos_origen={campos_origen}
                        onSelectedConfChange={onSelectedConfChange}
                        configuracion_salida={configuracion_salida}
                        lang={lang}
                        fullScreen={fullScreen}
                        setFullScreen={setFullScreen}
                        languages={languages}
                        Alert={Alert}
                        librerias={librerias}
                        configuracion_entrada={configuracion_entrada}
                        conectores={conectores}
                        selectedConf={selectedConf}
                        setLoading={setLoading}
                    />
                </DialogContent>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ padding: "1em 1em 1em 0em" }}>
                            {onCancel &&
                                <Button variant="text" onClick={onCancel}>
                                    Cancelar
                                </Button>
                            }
                            {onSave &&
                                <Button variant="contained" onClick={onSave} >
                                    Guardar
                                </Button>
                            }
                        </div>
                    </div>
            </Dialog>
        </>
    )
}

export default Script