import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import CloseRoundedIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import api from '../../../utils/api';
import { useSnackbar } from 'notistack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TagsInput from '../../../components/TagsInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Settings = ({
    flujo,
    setFlujo,
    open,
    handleClose,
    handleSave,
    setLoading,
    tags = [],
    showGeneral = true,
}) => {

    const { enqueueSnackbar: Alert } = useSnackbar();

    const handleSaveFlujo = () => {
        const { variables, descripcion, tags, use_cache } = flujo;
        setLoading(true);
        api.Post(`flujo/${flujo.id}/save_settings/`, {
            variables,
            descripcion,
            tags,
            use_cache
        }).then(response => {
            if (response.status === 200) {
                Alert('Configuración guardada correctamente', { variant: 'success' });
                handleClose();
            } else {
                Alert('Error al guardar la configuración', { variant: 'error' });
            }
        }).catch(error => {
            Alert('Error al guardar la configuración', { variant: 'error' });
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Configuraciones generales
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {showGeneral && (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                General
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ width: "100%", display: "flex", flexDirection: 'column' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingRight: '0.5em' }}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                label="Descripción"
                                                value={flujo?.descripcion || null}
                                                onChange={(e) => setFlujo({ ...flujo, descripcion: e.target.value })}
                                            />
                                        </div>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingLeft: '0.5em' }}>
                                            <TagsInput
                                                selectedTags={flujo?.tags || []}
                                                tags={tags}
                                                setTags={(tags) => setFlujo({ ...flujo, tags })}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingTop: '1em' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingRight: '0.5em' }}>
                                            <FormControlLabel
                                                label="Utilizar caché"
                                                control={
                                                    <Checkbox
                                                        checked={flujo?.use_cache}
                                                        onChange={(e) => setFlujo({ ...flujo, use_cache: e.target.checked })}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Variables
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        {flujo?.variables?.map((variable, index) => {
                                            return (
                                                <div style={{ display: 'flex', padding: "0.5em 0em", width: "100%", flexDirection: 'row' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '4%', alignSelf: 'center' }} >
                                                        <IconButton
                                                            onClick={() => {
                                                                let newVariables = flujo.variables;
                                                                newVariables.splice(index, 1);
                                                                setFlujo({ ...flujo, variables: newVariables });
                                                            }}
                                                        >
                                                            <CloseRoundedIcon fontSize='small' />
                                                        </IconButton>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '48%', padding: '0em 0.5em' }} >
                                                        <TextField
                                                            fullWidth
                                                            label="Nombre"
                                                            value={flujo?.variables[index]?.nombre || null}
                                                            onChange={(e) => {
                                                                let newVariables = flujo.variables;
                                                                newVariables[index] = { ...newVariables[index], nombre: e.target.value }
                                                                setFlujo({ ...flujo, variables: newVariables });
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', width: '48%', padding: '0em 0.5em' }} >
                                                        <TextField
                                                            fullWidth
                                                            label="Valor"
                                                            value={flujo?.variables[index]?.valor || null}
                                                            onChange={(e) => {
                                                                let newVariables = flujo.variables;
                                                                newVariables[index] = { ...newVariables[index], valor: e.target.value }
                                                                setFlujo({ ...flujo, variables: newVariables });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div style={{ width: "25%", flexDirection: 'row', alignSelf: 'flex-end' }} >
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    let newVariables = flujo.variables;
                                                    newVariables.push(null);
                                                    setFlujo({ ...flujo, variables: newVariables });
                                                }}
                                                startIcon={<AddIcon />}
                                                style={{ width: "100%" }}
                                            >
                                                Agregar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ padding: "1em 1em 1em 0em" }}>
                        <Button variant="text" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={handleSave || handleSaveFlujo} >
                            Guardar
                        </Button>
                    </div>
                </div>
            </Dialog >
        </>
    );

}

export default Settings;