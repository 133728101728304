import React, { useEffect } from "react";
import DataGrid, {
    Column,
    Grouping,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    ColumnChooser,
    GroupPanel,
    SearchPanel,
    Sorting,
    StateStoring,
} from "devextreme-react/data-grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import "moment/locale/es";
import api from '../../utils/api'
import { useSnackbar } from 'notistack';
import 'devextreme/dist/css/dx.light.css'; // O el tema que desees
import cronstrue from 'cronstrue/i18n';

function Tarea({
}) {

    const { enqueueSnackbar: Alert } = useSnackbar();

    const [tareas, setTareas] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        api.Get('tarea')
            .then((response) => {
                const { data } = response || {}
                setTareas(data || [])
            })
            .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    function changeExpanded(index, value = false) {
        let temp = tareas
        for (var i = 0; i < temp.length; i++) {
            if (i == index) {
                temp[i].expanded = value
            }
        }
        setTareas(temp)
    }

    return (
        <div style={{ margin: "0 40px 40px 40px" }}>
            <DataGrid
                dataSource={tareas}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="nextColumn"
            >
                <Column dataField="id" caption="id" dataType="number" visible={false} />
                <Column
                    dataField="flujo"
                    caption="Flujo"
                    calculateGroupValue={(data) => `${data.flujo.nombre} (${data.flujo.id})`}
                    calculateCellValue={(data) => `${data.flujo.nombre} (${data.flujo.id})`}
                    cellRender={(rowInfo) => {
                        const flujo = rowInfo.data.flujo
                        return (
                            <span>
                                {flujo.nombre} ({flujo.id})
                            </span>
                        )
                    }
                    }
                    groupIndex={0}
                />
                <Column
                    dataField="nombre"
                    caption="Nombre"
                />
                <Column
                    dataField="cron"
                    caption="Ejecución"
                    calculateGroupValue={(data) => `${cronstrue.toString(data.cron, { locale: "es" })}`}
                    calculateCellValue={(data) => `${cronstrue.toString(data.cron, { locale: "es" })}`}
                    cellRender={(rowInfo) => {
                        const cron = rowInfo.data.cron
                        return (
                            <span>
                                {cronstrue.toString(cron, { locale: "es" })}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    dataType="string"
                    dataField="pausado"
                    caption="Estado"
                    calculateGroupValue={(data) => `${data.pausado ? 'Pausado' : 'Activo'}`}
                    calculateCellValue={(data) => `${data.pausado ? 'Pausado' : 'Activo'}`}
                    cellRender={(rowInfo) => {
                        const pausado = rowInfo.data.pausado
                        return (
                            <span>
                                {pausado ? 'Pausado' : 'Activo'}
                            </span>
                        )
                    }
                    }
                />
                <Grouping autoExpandAll={true} />
                <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'BitacoraGRID'}
                />
                <ColumnChooser
                    enabled={true}
                    mode="dragAndDrop"
                />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={50} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[50, 100, 150, 200]}
                    showInfo={true}
                />
            </DataGrid>
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Tarea