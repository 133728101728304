import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import EditableText from '../../../../components/editableText';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { routes_conditions, routes_operators } from '../../../../utils/constants';

const setEdgeLabel = (edges, source, routes) => {
    const cleanedEdges = edges.map((edge) => {
        if (edge.source == source) edge.label = null
        return edge
    })
    const newEdges = cleanedEdges.map((edge) => {
        if (edge.source === source) {
            const _routes = routes.filter((route) => `conector:${route.target}` === edge.target)
            _routes.forEach((route) => {
                const ofLabel = edge.label
                edge.label = ofLabel && ofLabel != '' ? ofLabel + ' / ' + route.nombre : route.nombre
            })
        }
        return edge
    })
    return newEdges
}

const Router = ({
    id,
    index,
    value,
    route,
    targets,
    onSelectedConfChange,
    configuracion_salida,
    campos_origen,
    edges,
    setEdges,
}) => {
    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: 'row', justifyContent: "flex-start", padding: "1em 2em 0em 2em" }}>
                <EditableText
                    // spanBorder
                    editable={!route.default}
                    spanStyle={{ cursor: 'text', fontSize: '1em', fontWeight: 'bold' }}
                    containerStyle={{ margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                    inline={false}
                    initialText={route.nombre}
                    placeholder="Nombre"
                    onChange={(event) => {
                        const newCampos = configuracion_salida?.routes || []
                        newCampos[index].nombre = event.target.value
                        onSelectedConfChange({
                            configuracion_salida: {
                                ...configuracion_salida || {},
                                routes: newCampos
                            }
                        })
                        setEdges(
                            setEdgeLabel(
                                edges, id,
                                configuracion_salida?.routes || []
                            )
                        )
                    }}
                />
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: 'row', justifyContent: "flex-start", padding: "0em 2em 0em 2em" }}>
                {!route.default &&
                    <CancelIcon
                        style={{ alignSelf: 'center', cursor: 'pointer' }}
                        onClick={() => {
                            const newRoutes = configuracion_salida?.routes || []
                            newRoutes.splice(index, 1)
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    routes: newRoutes
                                }
                            })
                            setEdges(
                                setEdgeLabel(
                                    edges, id,
                                    configuracion_salida?.routes || []
                                )
                            )
                        }}
                    />
                }
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0em 0.5em 0em 0.5em' }}>
                    {/* CONDICIONALES SI NO ES EL DEFAULT */}
                    {!route.default &&
                        <>
                            {/* CONDICIONES */}
                            {route.conditions?.map((condition, indexCondition) => {
                                return (
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1em 0em 0em 0em' }}>
                                        <CancelIcon
                                            style={{ alignSelf: 'center', cursor: 'pointer' }}
                                            onClick={() => {
                                                const routes = configuracion_salida?.routes || []
                                                const conditions = routes[index].conditions || []
                                                conditions.splice(indexCondition, 1)
                                                routes[index].conditions = conditions
                                                onSelectedConfChange({
                                                    configuracion_salida: {
                                                        ...configuracion_salida || {},
                                                        routes
                                                    }
                                                })
                                            }}
                                        />
                                        {/* CAMPO */}
                                        <div style={{ display: 'flex', width: "32%" }}>
                                            <Autocomplete
                                                id={`route-${index}-condition-${indexCondition}-campo`}
                                                disablePortal
                                                value={campos_origen.find((campo) => campo === route?.conditions[indexCondition]?.campo) || null}
                                                options={campos_origen}
                                                onChange={(event, newValue) => {
                                                    const routes = configuracion_salida?.routes || []
                                                    const conditions = routes[index].conditions || []
                                                    conditions[indexCondition].campo = newValue
                                                    routes[index].conditions = conditions
                                                    onSelectedConfChange({
                                                        configuracion_salida: {
                                                            ...configuracion_salida || {},
                                                            routes
                                                        }
                                                    })

                                                }}
                                                renderInput={(params) => <TextField {...params} label="Campo" />}
                                                style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                            />
                                        </div>
                                        {/* CONDICIONAL */}
                                        <div style={{ display: 'flex', width: "32%" }}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`route-${index}-condition-${indexCondition}-condicional-label`}>
                                                    Condicional
                                                </InputLabel>
                                                <Select
                                                    labelId={`route-${index}-condition-${indexCondition}-condicional-label`}
                                                    id={`route-${index}-condition-${indexCondition}-condicional`}
                                                    label="Condicional"
                                                    style={{ width: "100%", background: "white" }}
                                                    value={route?.conditions[indexCondition]?.condition || ''}
                                                    onChange={(event) => {
                                                        const routes = configuracion_salida?.routes || []
                                                        const conditions = routes[index].conditions || []
                                                        conditions[indexCondition].condition = event.target.value
                                                        routes[index].conditions = conditions
                                                        onSelectedConfChange({
                                                            configuracion_salida: {
                                                                ...configuracion_salida || {},
                                                                routes
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {routes_conditions?.map((condition) => (
                                                        <MenuItem
                                                            key={`${condition.nombre} - ${condition.value}`}
                                                            value={condition.value}
                                                        >
                                                            <Tooltip title={condition.hint} placement='left'>
                                                                {`${condition.nombre}`}
                                                            </Tooltip>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* VALOR */}
                                        <div style={{ display: 'flex', width: "32%" }}>
                                            <TextField
                                                label="Valor"
                                                variant="outlined"
                                                value={route?.conditions[indexCondition]?.valor || ''}
                                                onChange={(event) => {
                                                    const routes = configuracion_salida?.routes || []
                                                    const conditions = routes[index].conditions || []
                                                    conditions[indexCondition].valor = event.target.value
                                                    routes[index].conditions = conditions
                                                    onSelectedConfChange({
                                                        configuracion_salida: {
                                                            ...configuracion_salida || {},
                                                            routes
                                                        }
                                                    })
                                                }}
                                                style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                            {/* AGREGAR CONDICION */}
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        style={{ margin: "1em 0.5em 1em 0em" }}
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            const routes = configuracion_salida?.routes || []
                                            const conditions = routes[index]?.conditions || []
                                            conditions.push({
                                                campo: null,
                                                condition: null,
                                                valor: null
                                            })
                                            routes[index].conditions = conditions
                                            onSelectedConfChange({
                                                configuracion_salida: {
                                                    ...configuracion_salida || {},
                                                    routes
                                                }
                                            })
                                        }}
                                    >
                                        Condición
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                    {/* Seleccion de TARGET Y OPERADOR */}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', padding: "1em 0em", width: route.default ? "100%" : "60%" }}>
                            <Autocomplete
                                id={`route-${index}-target`}
                                options={targets}
                                value={targets.find((target) => target.id === route?.target) || null}
                                getOptionLabel={(option) => option?.nombre + ' - ' + option?.descripcion || ''}
                                getOptionKey={(option) => option.id || ''}
                                onChange={(event, newValue) => {
                                    const routes = configuracion_salida?.routes || []
                                    routes[index].target = newValue?.id
                                    console.log('newautotarget', newValue, routes[index])
                                    onSelectedConfChange({
                                        configuracion_salida: {
                                            ...configuracion_salida || {},
                                            routes
                                        }
                                    })
                                    setEdges(
                                        setEdgeLabel(
                                            edges, id,
                                            configuracion_salida?.routes || []
                                        )
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} label="Conector" />}
                                style={{ width: "100%", background: "white", margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                            />
                        </div>
                        {!route.default &&
                            <div style={{ display: 'flex', padding: "1em 1em", width: "40%", justifyContent: 'center' }}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Operador</FormLabel>
                                    <RadioGroup
                                        row
                                        value={route.operador}
                                        onChange={(event) => {
                                            const newRoutes = configuracion_salida?.routes || []
                                            newRoutes[index].operador = event.target.value
                                            onSelectedConfChange({
                                                configuracion_salida: {
                                                    ...configuracion_salida || {},
                                                    routes: newRoutes
                                                }
                                            })
                                        }}
                                    >
                                        {routes_operators?.map((operator) => {
                                            return (
                                                <FormControlLabel value={operator.value} control={<Radio />} label={operator.nombre} />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Router