import React from 'react';
import { Api } from '../../../utils/api';
import { useParams, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/KeyboardBackspace';

const api = new Api('datosMaestro')

const CrearMaestro = () => {

    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar: Alert } = useSnackbar();

    const [action, setAction] = React.useState('')
    const [maestro, setMaestro] = React.useState({})
    const [origenes, setOrigenes] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [editEnabled, setEditEnabled] = React.useState(false)

    React.useEffect(() => {
        const { id } = params || {}
        const { pathname } = location || { pathname: '' }
        // Obtener info de los origenes
        setLoading(true)
        api.Get(`origen`)
            .then((response) => {
                const { data } = response || {}
                console.log({ data })
                setOrigenes(data || [])
                if (id) { // se busca el maestro si se provee id
                    api.Get(`maestro/${id}`)
                        .then((response) => {
                            const { data } = response || {}
                            setMaestro(data || {})
                        })
                        .catch(e => {
                            const { name, message, code } = e || {}
                            Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                } else {
                    setLoading(false)
                }
            })
            .catch(e => {
                const { name, message, code } = e || {}
                Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                setLoading(false)
            })
        // se habilita la edición si se está en la ruta de edición o creación
        setEditEnabled(pathname?.includes('editar') || pathname?.includes('crear'))
        if (pathname?.includes('editar')) {
            setAction('Editar')
        } else if (pathname?.includes('crear')) {
            setAction('Crear')
        } else {
            setAction('Info')
        }
    }, [])

    function handleSubmit() {
        setLoading(true)
        const { idmaestro } = maestro || {}
        if (idmaestro) {
            api.Put(`maestro/${idmaestro}`, maestro)
                .then((response) => {
                    const { data } = response || {}
                    Alert(`Maestro actualizado`, { variant: 'success' })
                    navigate('/?tab=maestros')
                })
                .catch(e => {
                    const { name, message, code } = e || {}
                    Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            api.Post(`maestro`, maestro)
                .then((response) => {
                    const { data } = response || {}
                    Alert(`Maestro creado`, { variant: 'success' })
                    navigate('/?tab=maestros')
                })
                .catch(e => {
                    const { name, message, code } = e || {}
                    Alert(`${name} [${code}]: ${message}`, { variant: 'error' })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* TITULO */}
            <h1 style={{ textAlign: 'center' }}>
                <IconButton aria-label="view" onClick={() => {
                    navigate(`/?tab=maestros`)
                }}
                    style={{ position: 'absolute', left: '0', marginLeft: '2em'}}
                >
                    <BackIcon sx={{ color: "black" }} />
                </IconButton>
                {action} maestro
            </h1>
            {/* FORMULARIO */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignSelf: 'center' }}>
                    <div style={{ padding: "0em 1em 1em 0em", width: "50%" }}>
                        <TextField
                            required
                            id="tablaalias"
                            label="Alias"
                            value={maestro?.tablaalias || ''}
                            onChange={(e) => setMaestro({ ...maestro, tablaalias: e.target.value })}
                            style={{ width: "100%", background: "white" }}
                            disabled={!editEnabled}
                        />
                    </div>
                    <div style={{ padding: "0em 1em 0em 1em", width: "50%" }}>
                        <Autocomplete
                            id={`idorigen`}
                            disablePortal
                            value={origenes.find((origen) => origen.idorigen === maestro?.idorigen) || null}
                            options={origenes}
                            getOptionLabel={(option) => option?.nombre || ''}
                            getOptionKey={(option) => option?.idorigen || ''}
                            onChange={(event, newValue) => {
                                setMaestro({ ...maestro, idorigen: newValue.idorigen })
                            }}
                            disabled={!editEnabled}
                            renderInput={(params) => <TextField {...params} label="Origen" />}
                            style={{ width: "100%", background: "white", alignSelf: 'center' }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignSelf: 'center' }}>
                    <div style={{ padding: "0em 1em 1em 0em", width: "50%" }}>
                        <TextField
                            required
                            id="filtropadre"
                            label="Filtro"
                            value={maestro?.filtropadre || ''}
                            onChange={(e) => setMaestro({ ...maestro, filtropadre: e.target.value })}
                            style={{ width: "100%", background: "white" }}
                            disabled={!editEnabled}
                        />
                    </div>
                    <div style={{ padding: "0em 1em 0em 1em", width: "50%" }}>
                        <TextField
                            required
                            id="sistemadestino"
                            label="Destino"
                            value={maestro?.sistemadestino || ''}
                            onChange={(e) => setMaestro({ ...maestro, sistemadestino: e.target.value })}
                            style={{ width: "100%", background: "white" }}
                            disabled={!editEnabled}
                        />
                    </div>
                </div>
            </div>
            {/* CODIGO */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center', overflow: 'auto' }}>
                <CodeMirror
                    value={maestro?.query || ""}
                    height="auto"
                    theme="dark"
                    extensions={[langs.sql()]}
                    onChange={(value, viewUpdate) => {
                        setMaestro({ ...maestro, query: value })
                    }}
                    readOnly={!editEnabled}
                    style={{ width: "100%", padding: "0em 1em 0em 0em", maxHeight: "300px" }}
                />
            </div>
            {editEnabled &&
                <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
                    <div style={{ alignSelf: 'flex-end', padding: '1em 1em 0em 0em' }}>
                        <Button autoFocus onClick={() => {
                            navigate('/?tab=maestros')
                        }}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleSubmit()
                            }}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            }
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default CrearMaestro;