import React, { useCallback } from 'react';

import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EditableText from '../../../../components/editableText';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'

const tooltip = {
    placement: 'left',
}

const tabs = [
    "G", "H", "QP", "B"
]

export default function OrigenApi({
    selectedConf,
    onSelectedConfChange,
    origenAttrs
}) {

    const { api_action, api_query_params, api_headers, api_body } = origenAttrs || {}

    React.useEffect(() => {
        let tab = null, headers = null, query_params = null, body = null

        if (!selectedConf?.data?.tab || !tabs.includes(selectedConf?.data?.tab)) {
            tab = "G"
        }
        if (!selectedConf?.data?.api_headers) {
            headers = {}
            api_headers?.forEach((header) => {
                headers[header] = ""
            })
        }
        if (!selectedConf?.data?.api_query_params) {
            query_params = {}
            api_query_params?.forEach((param) => {
                query_params[param] = ""
            })
        }
        if (!selectedConf?.data?.api_body) {
            body = {}
            api_body?.forEach((key) => {
                body[key] = ""
            })
        }

        const params = {}

        if (tab) {
            params.tab = tab
        }
        if (headers) {
            params.api_headers = headers
        }
        if (query_params) {
            params.api_query_params = query_params
        }
        if (body) {
            params.api_body = body
        }

        if (Object.keys(params).length > 0) {
            onSelectedConfChange({ ...params })
        }
    }, [])

    const sync_query_params = () => {
        api_query_params?.forEach(param => {
            let item = selectedConf?.data?.api_query_params || {}
            item = item[param]
            if (!item) {
                const query_params = selectedConf?.data?.api_query_params || {}
                query_params[param] = ""
                onSelectedConfChange({
                    api_query_params: query_params
                })
            }
        });
    }

    const sync_headers = () => {
        api_headers?.forEach(header => {
            let item = selectedConf?.data?.api_headers || {}
            item = item[header]
            if (!item) {
                const headers = selectedConf?.data?.api_headers || {}
                headers[header] = ""
                onSelectedConfChange({
                    api_headers: headers
                })
            }
        });
    }

    const sync_body = () => {
        api_body?.forEach(key => {
            let item = selectedConf?.data?.api_body || {}
            item = item[key]
            if (!item) {
                const body = selectedConf?.data?.api_body || {}
                body[key] = ""
                onSelectedConfChange({
                    api_body: body
                })
            }
        });
    }


    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ padding: "0em 2em 1em 2em", width: "100%" }}>
                    <TabContext value={selectedConf?.data?.tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={(e, value) => {
                                onSelectedConfChange({ tab: value })
                            }}
                                aria-label="Tipo request">
                                <Tab label="General" value="G" />
                                <Tab label="Headers" value="H" />
                                <Tab label="Query Params" value="QP" />
                                {['POST', 'PUT', 'PATCH'].includes(api_action) && <Tab label="Body" value="B" />}
                            </TabList>
                        </Box>
                        {/* GENERAL PANEL */}
                        <TabPanel value="G">
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <TextField
                                        id="nested_prop"
                                        label="Propiedad Anidada"
                                        value={selectedConf?.data?.nested_prop}
                                        onChange={(e) => onSelectedConfChange({ nested_prop: e.target.value })}
                                        style={{ width: "100%", background: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={selectedConf?.data?.flat_data || false}
                                                onChange={(e) => onSelectedConfChange({ flat_data: e.target.checked })}
                                            />
                                        }
                                        label="¿Aplanar data?"
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        {/* HEADERS PANEL */}
                        <TabPanel value="H" style={{ paddingTop: "0.5em" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                                    <Tooltip title="Refrescar" placement={tooltip.placement}>
                                        <IconButton
                                            onClick={() => {
                                                sync_headers()
                                            }}
                                        >
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                {Object.keys(selectedConf?.data?.api_headers || {}).map((header, index) => {
                                    return (
                                        <>
                                            <Grid item xs={5} style={{ display: 'flex' }}>
                                                <CancelIcon
                                                    style={{ alignSelf: 'center', cursor: 'pointer', marginRight: "0.5em" }}
                                                    onClick={() => {
                                                        const headers = selectedConf?.data?.api_headers || {}
                                                        delete headers[header]
                                                        onSelectedConfChange({
                                                            api_headers: headers
                                                        })
                                                    }}
                                                />
                                                <EditableText
                                                    spanBorder
                                                    spanStyle={{ cursor: 'text' }}
                                                    containerStyle={{ alignSelf: 'center' }}
                                                    inline={false}
                                                    initialText={header}
                                                    placeholder="Nombre del encabezado"
                                                    onChange={(event) => {
                                                        const headers = selectedConf?.data?.api_headers || {}
                                                        const new_headers = {}
                                                        Object.keys(headers).forEach((key, i) => {
                                                            if (key === header) {
                                                                new_headers[event.target.value] = headers[key]
                                                            } else {
                                                                new_headers[key] = headers[key]
                                                            }
                                                        })
                                                        onSelectedConfChange({
                                                            api_headers: new_headers
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    id={`h_name_${index}`}
                                                    label="Valor"
                                                    value={selectedConf?.data?.api_headers[header] || ""}
                                                    onChange={(event) => {
                                                        const headers = selectedConf?.data?.api_headers || {}
                                                        headers[header] = event.target.value
                                                        onSelectedConfChange({
                                                            api_headers: headers
                                                        })
                                                    }}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", marginTop: "1em", justifyContent: "flex-end" }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            const headers = selectedConf?.data?.api_headers || {}
                                            headers[""] = ""
                                            onSelectedConfChange({
                                                api_headers: headers
                                            })
                                        }}
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        {/* QUERY PARAMS PANEL */}
                        <TabPanel value="QP" style={{ paddingTop: "0.5em" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                                    <Tooltip title="Refrescar" placement={tooltip.placement}>
                                        <IconButton
                                            onClick={() => {
                                                sync_query_params()
                                            }}
                                        >
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                {Object.keys(selectedConf?.data?.api_query_params || {}).map((param, index) => {
                                    return (
                                        <>
                                            <Grid item xs={5} style={{ display: 'flex' }}>
                                                <CancelIcon
                                                    style={{ alignSelf: 'center', cursor: 'pointer', marginRight: "0.5em" }}
                                                    onClick={() => {
                                                        const query_params = selectedConf?.data?.api_query_params || {}
                                                        delete query_params[param]
                                                        onSelectedConfChange({
                                                            api_query_params: query_params
                                                        })
                                                    }}
                                                />
                                                <EditableText
                                                    spanBorder
                                                    spanStyle={{ cursor: 'text' }}
                                                    containerStyle={{ alignSelf: 'center' }}
                                                    inline={false}
                                                    initialText={param}
                                                    placeholder="Nombre del encabezado"
                                                    onChange={(event) => {
                                                        const query_params = selectedConf?.data?.api_query_params || {}
                                                        const new_params = {}
                                                        Object.keys(query_params).forEach((key, i) => {
                                                            if (key === param) {
                                                                new_params[event.target.value] = query_params[key]
                                                            } else {
                                                                new_params[key] = query_params[key]
                                                            }
                                                        })
                                                        onSelectedConfChange({
                                                            api_query_params: new_params
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    id={`qp_name_${index}`}
                                                    label="Valor"
                                                    value={selectedConf?.data?.api_query_params[param] || ""}
                                                    onChange={(event) => {
                                                        const query_params = selectedConf?.data?.api_query_params || {}
                                                        query_params[param] = event.target.value
                                                        onSelectedConfChange({
                                                            api_query_params: query_params
                                                        })
                                                    }}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", marginTop: "1em", justifyContent: "flex-end" }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            const query_params = selectedConf?.data?.api_query_params || {}
                                            query_params[""] = ""
                                            onSelectedConfChange({
                                                api_query_params: query_params
                                            })
                                        }}
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        {/* BODY PANEL */}
                        <TabPanel value="B" style={{ paddingTop: "0.5em" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                                    <Tooltip title="Refrescar" placement={tooltip.placement}>
                                        <IconButton
                                            onClick={() => {
                                                sync_body()
                                            }}
                                        >
                                            <AutorenewIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                {Object.keys(selectedConf?.data?.api_body || {}).map((key, index) => {
                                    return (
                                        <>
                                            <Grid item xs={5} style={{ display: 'flex' }}>
                                                <CancelIcon
                                                    style={{ alignSelf: 'center', cursor: 'pointer', marginRight: "0.5em" }}
                                                    onClick={() => {
                                                        const body = selectedConf?.data?.api_body || {}
                                                        delete body[key]
                                                        onSelectedConfChange({
                                                            api_body: body
                                                        })
                                                    }}
                                                />
                                                <EditableText
                                                    spanBorder
                                                    spanStyle={{ cursor: 'text' }}
                                                    containerStyle={{ alignSelf: 'center' }}
                                                    inline={false}
                                                    initialText={key}
                                                    placeholder="Clave"
                                                    onChange={(event) => {
                                                        const body = selectedConf?.data?.api_body || {}
                                                        const new_body = {}
                                                        Object.keys(body).forEach((key, i) => {
                                                            if (key === key) {
                                                                new_body[event.target.value] = body[key]
                                                            } else {
                                                                new_body[key] = body[key]
                                                            }
                                                        })
                                                        onSelectedConfChange({
                                                            api_body: new_body
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    id={`b_name_${index}`}
                                                    label="Valor"
                                                    value={selectedConf?.data?.api_body[key] || ""}
                                                    onChange={(event) => {
                                                        const body = selectedConf?.data?.api_body || {}
                                                        body[key] = event.target.value
                                                        onSelectedConfChange({
                                                            api_body: body
                                                        })
                                                    }}
                                                    style={{ width: "100%", background: "white" }}
                                                />
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            <div style={{ width: "100%", display: "flex", flexDirection: 'column', justifyContent: "flex-start" }}>
                                <div style={{ display: 'flex', width: "100%", marginTop: "1em", justifyContent: "flex-end" }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            const body = selectedConf?.data?.api_body || []
                                            body[""] = ""
                                            onSelectedConfChange({
                                                api_body: body
                                            })
                                        }}
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        </>
    );
}