import React, { Component } from 'react'
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import EditableText from '../../../../components/editableText';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

const Concat = ({
    index,
    value,
    campo,
    campos_origen,
    onSelectedConfChange,
    configuracion_salida,
    configuracion_entrada,
}) => {
    const separador = configuracion_salida?.campos[index]?.separador || ''
    return (
        <>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                {/* Nombre del campo */}
                <div style={{ display: 'flex', padding: "0em 0.5em 1em 2em", width: "45%" }}>
                    <CancelIcon
                        style={{ alignSelf: 'center', cursor: 'pointer' }}
                        onClick={() => {
                            const newCampos = configuracion_salida?.campos || []
                            newCampos.splice(index, 1)
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    campos: newCampos
                                }
                            })
                        }}
                    />
                    <EditableText
                        spanBorder
                        spanStyle={{ cursor: 'text' }}
                        containerStyle={{ margin: '0em 0.5em 0em 0.5em', alignSelf: 'center' }}
                        inline={false}
                        initialText={campo.nombre}
                        placeholder="Nombre del campo"
                        onChange={(event) => {
                            const newCampos = configuracion_salida?.campos || []
                            newCampos[index].nombre = event.target.value
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    campos: newCampos
                                }
                            })
                        }}
                    />
                </div>
                {/* Seleccion de campos del origen */}
                <div style={{ display: 'flex', padding: "0em 0.5em 1em 0.5em", width: "45%" }}>
                    <Select
                        style={{ width: "100%", background: "white" }}
                        multiple
                        value={configuracion_salida?.campos[index]?.campos || []}
                        onChange={(event) => {
                            const newCampos = configuracion_salida?.campos || []
                            newCampos[index].campos = event.target.value
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    campos: newCampos
                                }
                            })
                        }}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                    return (
                                        <Chip
                                            key={value}
                                            label={value}
                                            style={{ background: '#D9D9D9' }}
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                            onDelete={() => {
                                                const newCampos = configuracion_salida?.campos || []
                                                newCampos[index].campos = newCampos[index].campos.filter((item) => item != value)
                                                onSelectedConfChange({
                                                    configuracion_salida: {
                                                        ...configuracion_salida || {},
                                                        campos: newCampos
                                                    }
                                                })
                                            }}
                                        />
                                    )
                                })}
                            </Box>
                        )}
                    >
                        {campos_origen?.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {/* Separador */}
                <div style={{ display: 'flex', padding: "0em 2em 1em 0em", width: "10%" }}>
                    <input
                        style={{ width: "100%", background: "white", alignSelf: 'center', borderColor: 'transparent' }}
                        value={configuracion_salida?.campos[index]?.separador || ''}
                        onChange={(event) => {
                            const newCampos = configuracion_salida?.campos || []
                            newCampos[index].separador = event.target.value
                            onSelectedConfChange({
                                configuracion_salida: {
                                    ...configuracion_salida || {},
                                    campos: newCampos
                                }
                            })
                        }}
                    />
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: '-0.6em 0em 0em 0em' }}>
                <small style={{ color: 'rgba(0, 0, 0, 0.5)', whiteSpace: 'pre-wrap' }}>
                    {configuracion_salida?.campos[index]?.campos?.reduce((obj, item) => {
                        return obj + (obj != '' ? separador : '') + item
                    }, '')}
                </small>
            </div>
        </>
    )
}

export default Concat