// EditableText.js
import React, { useState } from 'react';

const EditableText = ({ 
    initialText, placeholder, onChange, editable=true,
    inputStyle={}, spanBorder=false, inline=true, 
    spanStyle={}, containerStyle={}, focused=false
}) => {
    const [isEditing, setIsEditing] = useState(focused);
    const [text, setText] = useState(initialText);

    spanStyle = {
        display: 'block',
        wordWrap: 'break-word',
        ...(spanBorder ? { border: '2px solid #000000', borderRadius: '10px', padding: '0em 0.5em 0em 0.5em' } : {}),
        ...spanStyle,
    }

    containerStyle = {
        display: inline ? 'inline-block' : 'block', 
        width: !inline ? '100%' : 'auto',
        ...containerStyle
    }

    const handleClick = () => {
        if(editable) setIsEditing(true);
    };

    const handleChange = (event) => {
        if(editable) {
            if(onChange) onChange(event)
            setText(event.target.value);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        // Save the changes or perform any required actions here
    };

    React.useEffect(() => {
        setText(initialText)
    }, [initialText])

    return (
        <div 
            onClick={handleClick} 
            style={containerStyle}
        >
            {isEditing ? (
                <input
                    type="text"
                    value={text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    onKeyDown={(event) => {
                        if(event.key === "Enter") setIsEditing(false)
                    }}
                    autoFocus
                    style={{ 
                        borderRadius: '10px', padding: '0em 0.5em 0em 0.5em', 
                        fontFamily: "Roboto,Helvetica,Arial,sans-serif", fontSize: "1rem",
                        background: 'transparent', outline: 'none', border: '2px solid #000000',
                        wordBreak: 'break-word', display: 'block', width: '100%',
                        ...inputStyle
                    }}
                />
            ) : (
                <span style={spanStyle}>{text && text != '' ? text : placeholder}</span>
            )}
        </div>
    );
};

export default EditableText;