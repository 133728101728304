import React, { useEffect, useRef } from "react";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
    Item,
    Grouping,
    GroupPanel,
    StateStoring,
    ColumnChooser,
} from "devextreme-react/data-grid";
import 'devextreme/dist/css/dx.light.css'; // O el tema que desees

import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import api from "../../../../utils/api";

function S3Files({
    files=[],
    setLoading=(val)=>{}
}) {

    const { enqueueSnackbar: Alert } = useSnackbar();

    return (
        <div style={{ margin: "0 40px 40px 40px" }}>
            <DataGrid
                dataSource={files}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode="nextColumn"
            >
                <Toolbar>
                    <Item name="searchPanel" />
                </Toolbar>
                <Column
                    dataField="s3_uri"
                    caption="S3 URI"
                    calculateGroupValue={(data) => `${data}`}
                    calculateCellValue={(data) => `${data}`}
                    cellRender={(rowInfo) => {
                        const splitted = rowInfo.data?.split('/') || []
                        const s3_uri = splitted.length > 0 ? splitted[splitted.length - 1] : ''
                        return (
                            <span>
                                <IconButton
                                    aria-label="copy"
                                    onClick={() => {
                                        navigator.clipboard.writeText(rowInfo.data);
                                        Alert(`URI copiada.`, { variant: 'success', autoHideDuration: 1500 })
                                    }}
                                >
                                    <CopyIcon />
                                </IconButton>
                                {s3_uri}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    width={'auto'}
                    allowFiltering={false}
                    allowSorting={false}
                    allowSearch={false}
                    allowGrouping={false}
                    allowReordering={false}
                    allowResizing={false}
                    cellRender={(rowInfo) => {
                        const s3_uri = rowInfo.data
                        return (
                            <span>
                                <IconButton aria-label="download" onClick={() => {
                                    setLoading(true)
                                    api.Get('conector/download_s3_file', {
                                        s3_uri
                                    }, 'blob')
                                    .then((response) => {
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', s3_uri.split('/').pop());
                                        document.body.appendChild(link);
                                        link.click();
                                    })
                                    .catch((error) => {
                                        Alert(`Error al descargar el archivo.`, { variant: 'error', autoHideDuration: 1500 })
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                                }}>
                                    <DownloadIcon sx={{ color: "#220022" }} />
                                </IconButton>
                            </span>
                        )
                    }
                    }
                />
                <Grouping autoExpandAll={true} />
                <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={'hojasS3GRID'}
                />
                <ColumnChooser
                    enabled={true}
                    mode="dragAndDrop"
                />
                <GroupPanel visible={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Paging defaultPageSize={50} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[50, 100, 150, 200]}
                    showInfo={true}
                />
            </DataGrid>
        </div>
    )
}

export default S3Files