import React from "react";
import {
  Box,
  Tab,
  Tabs
} from '@mui/material';
import PropTypes from 'prop-types';
import TaskIcon from '@mui/icons-material/Task';
import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SourceIcon from '@mui/icons-material/Source';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useSearchParams } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`main-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: '1rem' }}>{children}</Box>
      )}
    </div>
  );
}

const TABS = {
  flujos: 0,
  tareas: 1,
  maestros: 2,
  origenes: 3,
  destinos: 4,
  bitacora: 5,
  hojasS3: 6,
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MainTabs(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const [value, setValue] = React.useState(TABS[tab] || 0);

  const handleChange = (event, newValue) => {
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = { ...Object.fromEntries(currentParams), tab: Object.keys(TABS).find(key => TABS[key] === newValue) };
    setValue(newValue);
    setSearchParams(newParams);
  };

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab id={`main-tab-${0}`} icon={<CallSplitIcon />} iconPosition="start" label="Flujos" />
          <Tab id={`main-tab-${1}`} icon={<TaskIcon />} iconPosition="start" label="Tareas" />
          {/* <Tab id={`main-tab-${2}`} icon={<SourceIcon />} iconPosition="start" label="Maestros" /> */}
          <Tab id={`main-tab-${2}`} icon={<InputIcon />} iconPosition="start" label="Origenes" />
          <Tab id={`main-tab-${3}`} icon={<OutputIcon />} iconPosition="start" label="Destinos" />
          <Tab id={`main-tab-${4}`} icon={<ReceiptLongIcon />} iconPosition="start" label="Bitácora" />
          <Tab id={`main-tab-${5}`} icon={<FindInPageIcon />} iconPosition="start" label="Hojas de cálculo" />

          {/* <Tab id={`main-tab-${0}`} label="Flujos" /> */}
          {/* <Tab id={`main-tab-${1}`} label="Tareas" /> */}
          {/* <Tab id={`main-tab-${2}`} label="Origenes" /> */}
          {/* <Tab id={`main-tab-${3}`} label="Destinos" /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {props.flujosComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {props.tareasComponent}
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        {props.maestrosComponent}
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        {props.origenesComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {props.destinosComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {props.bitacoraComponent}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        {props.hojaS3Component}
      </CustomTabPanel>
    </div>
  );
}
